import React from 'react'
import { Skeleton } from 'antd-mobile'

const HeaderLoading: React.FC = () => {
  return (
    <Skeleton.Title
      animated
      className="fixed-top"
      style={{ backgroundColor: '#ffffff', width: '100%', height: '56px', margin: '0' }}
    />
  )
}

export default HeaderLoading
