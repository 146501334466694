import React, { useState } from 'react'
import { AutoCenter, Button, Dialog, List, NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { getCookie, setCookie } from 'react-use-cookie'
import { useRecoilValue } from 'recoil'
import { profileRecoil } from '../../recoils/profile'
import { getAuth, sendPasswordResetEmail, signOut } from '@firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils/Utils'
import { organizationRecoil } from '../../recoils/organization'
import { devicesCount } from '../../recoils/devices'
import { LANGUAGE, languageCurrentRecoil } from '../../recoils/language'
import { useTranslation } from 'react-i18next'

const User: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()
  const uid = getCookie('uid')
  const email = auth.currentUser?.email || ''
  const profile = useRecoilValue(profileRecoil(uid))
  const organization = useRecoilValue(organizationRecoil(uid))
  const [isDisableAccount, setIsDisableAccount] = useState(false)
  const [visibleDisableAccount, setVisibleDisableAccount] = useState(false)
  const amountDevices = useRecoilValue(devicesCount(uid))
  const languageCurrent = useRecoilValue(languageCurrentRecoil)

  const handleLogout = async () => {
    await signOut(auth)
      .then(() => {
        setCookie('deviceId', '')
        setCookie('uid', '')
        setCookie('deviceName', '')
        setCookie('organization', '')
        setCookie('email', '')
        Toast.show({
          content: t('user.message.logout.success'),
          duration: 3000,
        })
      })
      .catch(() => {
        Toast.show({
          content: t('user.message.logout.error'),
          duration: 3000,
        })
        // throw error to sentry
        throw new Error('Logout failed!')
      })
  }

  const handleDisableAccount = () => {
    const disableAccount = httpsCallable(functions, 'DisableAccount')
    setIsDisableAccount(true)
    disableAccount()
      .then((res) => {
        setIsDisableAccount(false)
        Toast.show({
          duration: 2000,
          content: t('user.message.delete-account.success'),
        })
        signOut(auth).then(() => {
          setCookie('deviceId', '')
          setCookie('uid', '')
          setCookie('deviceName', '')
          setCookie('organization', '')
          setCookie('email', '')
        })
      })
      .catch(() => {
        setIsDisableAccount(false)
        Toast.show({
          duration: 2000,
          content: t('user.message.delete-account.error'),
        })
      })
  }

  const handleSendResetPassword = async (event: any) => {
    event.stopPropagation()
    const actionCodeSettings = {
      url: `${window?.location.origin}/login`,
      handleCodeInApp: true,
    }
    auth.languageCode = languageCurrent
    await sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        Toast.show({
          content: t('user.message.send-reset-password.success'),
          position: 'top',
          duration: 3000,
        })
      })
      .catch((error) => {
        Toast.show({
          content: error.message,
          position: 'top',
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  return (
    <div className="container" style={{ padding: '75px 13px 0' }}>
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        backArrow={false}
        right={
          <Button color="primary" fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
      >
        {profile?.displayName}
      </NavBar>
      <Space style={{ fontSize: '15px', fontWeight: '700', color: '#4E4E4E', padding: '0 12px' }}>
        {t('user.personal-information')}
      </Space>
      <List header={t('アバター')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/user/edit/avatar')}>
          <div
            style={{
              width: '80px',
              height: '80px',
              backgroundImage: `url('${profile?.avatar}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: 'var(--avatar-border-radius)',
            }}
          />
        </List.Item>
      </List>
      <List header={t('user.display-name')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/user/edit/display-name')}>{profile?.displayName}</List.Item>
      </List>
      <Space style={{ fontSize: '15px', fontWeight: '700', color: '#4E4E4E', padding: '78px 12px 0' }}>
        {t('user.account')}
      </Space>
      <List header={t('user.email')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/user/edit/email')}>{email}</List.Item>
      </List>
      <List header={t('user.password')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item
          onClick={() => navigate('/user/edit/password')}
          extra={
            <Button
              style={{ fontSize: '13px', color: '#333333', padding: '4px 12px' }}
              onClick={async (event) => handleSendResetPassword(event)}
            >
              {t('user.button.reset')}
            </Button>
          }
        >
          {t('user.password.already')}
        </List.Item>
      </List>
      <List header={t('user.language')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/user/edit/language')}>
          {languageCurrent === LANGUAGE.ENGLISH.VALUE ? LANGUAGE.ENGLISH.NAME : LANGUAGE.JAPANESE.NAME}
        </List.Item>
      </List>
      {organization.limit && (
        <>
          <Space
            style={{ fontSize: '15px', fontWeight: '700', color: '#4E4E4E', padding: '0 12px', marginTop: '25px' }}
          >
            {t('user.limit')}
          </Space>
          <List style={{ '--border-bottom': 'none', '--border-top': 'none', '--border-inner': 'none' }}>
            <List.Item style={{ fontSize: '15px', color: '#666666', marginTop: '10px' }}>
              {t('user.call-time.month')}: {Math.floor(organization.callStats.totalCallTime / 60)}分 /{' '}
              {Math.floor(organization.limit.callTimePerMonth / 60)}分
            </List.Item>
            <List.Item style={{ fontSize: '15px', color: '#666666' }}>
              {t('user.number-devices')}: {amountDevices}台 / {organization.limit.devices}台
            </List.Item>
          </List>
        </>
      )}
      <List style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item>
          <Button
            color="default"
            loading="auto"
            shape="rectangular"
            style={{ marginTop: '24px' }}
            onClick={handleLogout}
          >
            {t('user.logout')}
          </Button>
        </List.Item>
      </List>
      <List style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item>
          <Button
            fill="outline"
            color="danger"
            loading="auto"
            shape="rectangular"
            style={{ marginTop: '24px' }}
            disabled={isDisableAccount}
            onClick={() => setVisibleDisableAccount(true)}
          >
            {t('user.delete-account')}
          </Button>
        </List.Item>
      </List>
      <AutoCenter
        style={{ minHeight: '100px', paddingBottom: '10px', fontSize: '15px', color: '#999999', alignItems: 'end' }}
      >
        {t('user.customer-id')}: {uid}
      </AutoCenter>
      <Dialog
        visible={visibleDisableAccount}
        header={<div style={{ fontSize: '16px', color: '#000000' }}>{t('user.delete-account.header')}</div>}
        content={
          <>
            <div>{t('user.delete-account.content1')}</div>
            <div>{t('user.delete-account.content2')}</div>
          </>
        }
        closeOnAction
        bodyStyle={{ maxWidth: '300px' }}
        onClose={() => setVisibleDisableAccount(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('user.delete-account.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'delete',
              text: t('user.delete-account.button.delete'),
              danger: true,
              onClick: handleDisableAccount,
            },
          ],
        ]}
      />
    </div>
  )
}

export default User
