import { atomFamily } from 'recoil'
import Manager from '../entities/Manager'
import { httpsCallable } from '@firebase/functions'
import { functions } from '../utils/Utils'

export const managersRecoil = atomFamily<Manager[], string>({
  key: 'managers',
  default: async (organizationId) => {
    if (!organizationId) {
      return []
    }

    const getManagers = httpsCallable(functions, 'GetManagers')
    const managers = await getManagers({})

    return (managers.data as { status: string; data: { managers: Manager[] } }).data.managers
  },
})
