import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { getCookie } from 'react-use-cookie'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const ENV_MANAGEMENT = process.env.REACT_APP_ENV_MANAGEMENT

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: ENV_MANAGEMENT,
  beforeSend: function (event) {
    const organization = getCookie('organization')
    const email = getCookie('email')
    const uid = getCookie('uid')
    const environment = event.environment || ''

    event.fingerprint = [
      `organization: ${organization}`,
      `email: ${email}`,
      `environment: ${environment}`,
      `uid: ${uid}`,
    ]

    return event
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
