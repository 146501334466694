import { httpsCallable } from '@firebase/functions'
import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { functions } from '../../utils/Utils'
import Video from 'twilio-video'
import CallTemplate from '../../components/call/CallTemplate'
import Participant from '../../components/call/Participant'
import { useNavigate } from 'react-router-dom'
import { call } from '../../recoils/call'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { CALL_STATUS } from '../../constants'
import { Toast } from 'antd-mobile'
import { getCookie } from 'react-use-cookie'
import CalleeInformation from '../../components/call/CalleeInformation'
import * as Sentry from '@sentry/react'
import { getAnalytics, logEvent } from '@firebase/analytics'
import { organizationRecoil } from '../../recoils/organization'
import { getAuth } from 'firebase/auth'
import { useTranslation } from 'react-i18next'

const cancelCall = httpsCallable(functions, 'CancelCall')

const CallPage: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()
  const deviceId = getCookie('deviceId')
  const [token, setToken] = useState('')
  const loaded = useRef(false)
  const ended = useRef(false)
  const [room, setRoom] = useState<any>(null)
  const [participants, setParticipants] = useState<any[]>([])
  const callSetting = useRecoilValue(call)
  const resetCallState = useResetRecoilState(call)
  const [callRef, setCallRef] = useState('')
  const videoPreviewRef = useRef<any>(null)
  const videoTrackRef = useRef<any>(null)
  const audioTrackRef = useRef<any>(null)
  const analytics = getAnalytics()
  const organization = useRecoilValue(organizationRecoil(auth.currentUser?.uid as string))

  //handle call refused
  useEffect(() => {
    if (callRef) {
      const db = getFirestore()
      const callCollection = doc(db, callRef)
      onSnapshot(callCollection, (snapshot: any) => {
        if (snapshot.data().status === CALL_STATUS.REFUSED) {
          Toast.show({
            content: t('call.message.refused'),
            position: 'top',
            duration: 3000,
          })
          navigate('/')
        }
      })
    }
  }, [callRef, navigate, t])

  //handle end call
  useEffect(() => {
    //End the call when the time limit comes
    if (!ended.current && room) {
      const timeCall = (organization?.callStats?.remainingCallTime || 1800) * 1000
      ended.current = true
      setTimeout(() => {
        Toast.show({
          content: t('call.message.limit'),
          position: 'top',
          duration: 5000,
        })
        navigate('/')
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.stop()
          publication.unpublish()
        })
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.stop()
          publication.unpublish()
        })
      }, timeCall)
    }

    if (callSetting.endCall) {
      room.localParticipant.videoTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })
      room.localParticipant.audioTracks.forEach((publication: any) => {
        publication.track.stop()
        publication.unpublish()
      })

      navigate('/')
      // When end call
      return () => {
        if (room) {
          room.localParticipant.videoTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })
          room.localParticipant.audioTracks.forEach((publication: any) => {
            publication.track.stop()
            publication.unpublish()
          })

          participants.length
            ? room.disconnect()
            : cancelCall({ callRef }).catch((error) => {
                throw new Error(error.message)
              })
        } else {
          audioTrackRef.current && audioTrackRef.current.stop()
          videoTrackRef.current && videoTrackRef.current.stop()
        }

        resetCallState()
      }
    }
  }, [
    callRef,
    callSetting.endCall,
    navigate,
    organization?.callStats?.remainingCallTime,
    participants.length,
    resetCallState,
    room,
    t,
  ])

  //handle on/off microphone
  useEffect(() => {
    if (room) {
      if (callSetting.muteMicrophone) {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteMicrophone, room])

  //handle on/off camera
  useEffect(() => {
    if (room) {
      if (callSetting.muteCamera) {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.disable()
          publication.track.restart()
        })
      } else {
        room.localParticipant.videoTracks.forEach((publication: any) => {
          publication.track.enable()
          publication.track.restart()
        })
      }
    }
  }, [callSetting.muteCamera, room])

  //handle  call
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true
      const makeCallBrowser = httpsCallable(functions, 'MakeCallBrowser')

      Promise.all([
        Video.createLocalVideoTrack({ name: 'camera' }),
        Video.createLocalAudioTrack({ name: 'microphone' }),
      ])
        .then((localTracks) => {
          videoTrackRef.current = localTracks[0]
          audioTrackRef.current = localTracks[1]
          if (!videoPreviewRef.current?.hasChildNodes()) {
            const localEl = localTracks[0]?.attach() as HTMLMediaElement
            localEl.style.width = '100%'
            localEl.style.height = 'calc(100% - 5px)'
            localEl.style.objectFit = 'cover'
            localEl.style.filter = 'blur(8px)'

            videoPreviewRef.current?.appendChild(localEl)
          }
          const organization = getCookie('organization')

          const transactionMakeCall = Sentry.startTransaction({ name: 'MakeCallBrowser' })
          Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transactionMakeCall))
          logEvent(analytics, 'make_call')
          makeCallBrowser({ organization, id: deviceId })
            .then((result: any) => {
              const spanMakeCall = transactionMakeCall.startChild({
                data: { result },
                op: 'call',
                description: `Make call browser`,
              })
              spanMakeCall.setStatus('Call success')
              spanMakeCall.finish()
              transactionMakeCall.finish()

              setToken(result.data.data.token)
              setCallRef(result.data.data.callRef)

              const transactionConnectRoom = Sentry.startTransaction({ name: 'ConnectRoom' })
              Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transactionConnectRoom))

              //connect room
              Video.connect(result.data.data.token, {
                name: deviceId,
                tracks: localTracks,
              }).then((roomResult) => {
                const spanConnectRoom = transactionConnectRoom.startChild({
                  data: { roomResult },
                  op: 'connect',
                  description: `Connect room`,
                })
                spanConnectRoom.setStatus('Connect room success')
                spanConnectRoom.finish()
                transactionConnectRoom.finish()
                setRoom(roomResult)
                roomResult.on('disconnected', (room: Video.Room) => {
                  room.localParticipant.tracks.forEach((publication: any) => {
                    const attachedElements = publication.track.detach()
                    attachedElements.forEach((element: any) => element.remove())
                    publication.track.stop()
                    publication.unpublish()
                  })
                  navigate('/')
                })
                roomResult.on('participantConnected', (participant: Video.Participant) => {
                  setParticipants([participant])
                })
              })
            })
            .catch((error) => {
              const spanMakeCall = transactionMakeCall.startChild({
                data: { error },
                op: 'call',
                description: `Make call browser`,
              })
              spanMakeCall.setStatus('Call failed')
              spanMakeCall.finish()
              transactionMakeCall.finish()

              Toast.show({
                content: error.message,
                position: 'top',
                duration: 3000,
              })
              navigate('/')
              throw new Error(error.message)
            })
        })
        .catch((error) => {
          window.alert(error.message)
          navigate('/')
          throw new Error(error.message)
        })
    }
    return () => {
      setRoom((currentRoom: Video.Room) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication: any) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
      resetCallState()
    }
  }, [analytics, deviceId, navigate, resetCallState])

  return (
    <CallTemplate room={room}>
      {token && room && participants.length ? (
        <>
          <div style={{ width: '115px', position: 'fixed', top: '14px', left: '14px' }}>
            {room && <Participant key={room.localParticipant.sid} participant={room.localParticipant} />}
          </div>
          {participants.map((participant) => (
            <Participant key={participant.sid} participant={participant} />
          ))}
        </>
      ) : (
        <>
          <CalleeInformation />
          <div ref={videoPreviewRef} />
        </>
      )}
    </CallTemplate>
  )
}

export default Sentry.withProfiler(CallPage)
