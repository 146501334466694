import React, { ReactNode } from 'react'
import { AutoCenter, Button, Grid, Space } from 'antd-mobile'
import { CameraIcon as CameraOn, MicrophoneIcon as MicrophoneOn, XMarkIcon } from '@heroicons/react/24/outline'
import { CameraIcon as CameraOff, MicrophoneIcon as MicrophoneOff } from '@heroicons/react/24/solid'
import { useRecoilState } from 'recoil'
import { call } from '../../recoils/call'
import { useTranslation } from 'react-i18next'

type CallTemplateProps = {
  children: ReactNode
  room: boolean
}

const CallTemplate: React.FC<CallTemplateProps> = ({ children, room }) => {
  const { t } = useTranslation()
  const [callSetting, setCallSetting] = useRecoilState(call)

  return (
    <div
      className="container"
      style={{
        backgroundColor: '#000000',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children}
      <Grid columns={3} style={{ color: '#ffffff', position: 'absolute', width: '100%', bottom: '23px', left: 0 }}>
        <Grid.Item>
          <Space
            align="center"
            justify="center"
            style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
          >
            {callSetting.muteCamera ? (
              <Space
                align="center"
                justify="center"
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                onClick={() => setCallSetting({ ...callSetting, muteCamera: false })}
              >
                <CameraOn style={{ width: '40px' }} />
                <Space>{t('call-template.turn-on-camera')}</Space>
              </Space>
            ) : (
              <Space
                align="center"
                justify="center"
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                onClick={() => setCallSetting({ ...callSetting, muteCamera: true })}
              >
                <CameraOff style={{ width: '40px' }} />
                <Space>{t('call-template.turn-off-camera')}</Space>
              </Space>
            )}
          </Space>
        </Grid.Item>

        <Grid.Item>
          <AutoCenter>
            <Button
              style={{
                borderRadius: '100%',
                width: '70px',
                height: '70px',
                backgroundColor: 'red',
                color: '#ffffff',
                cursor: 'pointer',
                border: 'none',
              }}
              disabled={!room}
              onClick={() => setCallSetting({ ...callSetting, endCall: true })}
            >
              <XMarkIcon style={{ width: '40px', marginTop: '6px' }} />
            </Button>
          </AutoCenter>
        </Grid.Item>
        <Grid.Item>
          {callSetting.muteMicrophone ? (
            <Space
              align="center"
              justify="center"
              style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
              onClick={() => setCallSetting({ ...callSetting, muteMicrophone: false })}
            >
              <MicrophoneOn style={{ width: '40px' }} />
              <Space>{t('call-template.turn-on-microphone')}</Space>
            </Space>
          ) : (
            <Space
              align="center"
              justify="center"
              style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
              onClick={() => setCallSetting({ ...callSetting, muteMicrophone: true })}
            >
              <MicrophoneOff style={{ width: '40px' }} />
              <Space>{t('call-template.turn-off-microphone')}</Space>
            </Space>
          )}
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default CallTemplate
