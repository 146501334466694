import { atomFamily } from 'recoil'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../utils/Utils'
import { Contact } from './contacts'
import { collection, getDocs, getFirestore, onSnapshot } from 'firebase/firestore'

export type DeviceManagement = {
  organization: string
  role: 'owner' | 'manager'
  data: {
    id: string
    avatar: string
    name: string
    displayName: string
    contacts: Omit<Contact, 'callStatus'>[]
  }
}

export const managementDevices = async () => {
  const getManagementDevices = httpsCallable(functions, 'GetManagementDevices')
  const result: any = await getManagementDevices({})
  return result.data.data as DeviceManagement[]
}

export const devicesRecoil = atomFamily<DeviceManagement[], string>({
  key: 'devices',
  default: async () => {
    const getManagementDevices = httpsCallable(functions, 'GetManagementDevices')
    const result: any = await getManagementDevices({})
    return result.data.data
  },
})

export const devicesCount = atomFamily<number, string>({
  key: 'devices.count',
  default: async (organizationId) => {
    const devices = await getDocs(collection(getFirestore(), `organizations/${organizationId}/devices`))
    return devices.size
  },
  effects: (organizationId) => {
    return [
      ({ setSelf }) => {
        const devicesRef = collection(getFirestore(), `organizations/${organizationId}/devices`)
        return onSnapshot(devicesRef, (devicesSnap) => {
          setSelf(devicesSnap.size)
        })
      },
    ]
  },
})
