import React, { useState, useEffect } from 'react'
import { Button, CapsuleTabs, NavBar, Space } from 'antd-mobile'
import { Bars3Icon, VideoCameraIcon, ArrowUpCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { profileRecoil } from '../../recoils/profile'
import { getCookie } from 'react-use-cookie'
import { DeviceManagement } from '../../recoils/devices'
import { AddOutline } from 'antd-mobile-icons'
import { useTranslation } from 'react-i18next'

type HeaderProps = {
  isExpand: boolean
  setIsExpand: (isExpand: boolean) => void
  deviceId: string
  organization: string
  setOrganization: (organization: string) => void
  setDeviceId: (deviceId: string) => void
  devices: DeviceManagement[]
  deviceName: string
}

const ADD_DEVICE = 'add-device'

const Header: React.FC<HeaderProps> = ({
  isExpand,
  setIsExpand,
  deviceId,
  setDeviceId,
  organization,
  setOrganization,
  deviceName,
  devices,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const uid = getCookie('uid')
  const profile = useRecoilValue(profileRecoil(uid))
  const [activeKey, setActiveKey] = useState(`${organization}/${deviceId}`)

  useEffect(() => {
    setActiveKey(`${organization}/${deviceId}`)
  }, [deviceId, organization])

  return (
    <div className="fixed-top" style={{ borderBottom: '1px solid #EEEEEE' }}>
      <NavBar
        style={{ '--height': '55px', position: 'relative', borderBottom: '1px solid #EEEEEE' }}
        onBack={() => navigate('/user')}
        backArrow={
          <div
            style={{
              width: '32px',
              height: '32px',
              backgroundImage: `url('${profile?.avatar}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: 'var(--avatar-border-radius)',
            }}
          />
        }
        right={
          <Space style={{ '--gap': '10px' }}>
            {deviceId && (
              <Button
                fill="none"
                style={{ width: '32px', height: '32px', padding: '0', borderRadius: '0', marginTop: '2px' }}
                onClick={() => navigate('/call')}
              >
                <VideoCameraIcon style={{ width: '32px', strokeWidth: '1' }} />
              </Button>
            )}
            {deviceId && (
              <Button
                fill="none"
                style={{ width: '32px', height: '32px', padding: '0', borderRadius: '0', marginTop: '2px' }}
                onClick={() => navigate('/device/detail')}
              >
                <Bars3Icon style={{ width: '32px', strokeWidth: '1' }} />
              </Button>
            )}
          </Space>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            position: 'relative',
            minHeight: '30px',
            height: '100%',
          }}
        >
          <div className="textTruncateMaxWidth">{deviceName || t('header.title.none')}</div>
          <Button
            fill="none"
            style={{
              position: 'absolute',
              right: 0,
              borderRadius: 'var(--adm-button-border-radius)',
              padding: '0',
              margin: '0',
              width: '30px',
              height: '30px',
            }}
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? (
              <ArrowUpCircleIcon style={{ width: '28px', strokeWidth: '1' }} />
            ) : (
              <ArrowDownCircleIcon style={{ width: '28px', strokeWidth: '1' }} />
            )}
          </Button>
        </div>
      </NavBar>
      <div
        style={{
          visibility: isExpand ? 'visible' : 'hidden',
          transition: 'all .3s',
          height: isExpand ? '58px' : 0,
          overflow: 'hidden',
        }}
      >
        {devices.length ? (
          <CapsuleTabs
            activeKey={activeKey}
            defaultActiveKey={`${organization}/${deviceId}`}
            onChange={(key) => {
              if (key === ADD_DEVICE) {
                navigate('/input-passcode')
                setActiveKey(`${organization}/${deviceId}`)
                return
              }
              setActiveKey(key)
              setOrganization(key.split('/')[0])
              setDeviceId(key.split('/')[1])
            }}
          >
            {devices.map(({ data, organization }) => (
              <CapsuleTabs.Tab title={data?.displayName} key={`${organization}/${data?.id}`} />
            ))}
            <CapsuleTabs.Tab title={<AddOutline fontSize={16} />} key={ADD_DEVICE}></CapsuleTabs.Tab>
          </CapsuleTabs>
        ) : (
          <Space style={{ width: '100%', padding: '12px', boxSizing: 'border-box' }}>
            <Button
              style={{
                borderRadius: 'var(--adm-button-border-radius)',
                padding: '4px 18px',
                backgroundColor: '#f5f5f5',
              }}
              onClick={() => navigate('/input-passcode')}
            >
              <AddOutline fontSize={16} onClick={() => navigate('/input-passcode')} />
            </Button>
          </Space>
        )}
      </div>
    </div>
  )
}

export default Header
