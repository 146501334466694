import React from 'react'
import { SpinLoading } from 'antd-mobile'

const Loading: React.FC = () => {
  return (
    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <SpinLoading color="primary" style={{ '--size': '48px' }} />
    </div>
  )
}

export default Loading
