import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from '../i18n/translationEN.json'
import japanese from '../i18n/translationJP.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: english,
    },
    ja: {
      translation: japanese,
    },
  },
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
