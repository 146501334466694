import React from 'react'
import { DotLoading, Space } from 'antd-mobile'
import { useRecoilValue } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { getCookie } from 'react-use-cookie'

const CalleeInformation: React.FC = () => {
  const uid = getCookie('uid')
  const deviceId = getCookie('deviceId')
  const device = useRecoilValue(deviceRecoil({ organizationId: uid, deviceId: deviceId }))

  return (
    <Space
      style={{ display: 'flex', flexDirection: 'column', position: 'fixed', top: '14px', left: '14px', zIndex: 2000 }}
    >
      <Space style={{ width: '150px', height: '112px', backgroundColor: '#756244' }}>
        <div
          style={{
            width: '150px',
            height: '112px',
            backgroundImage: `url('${device?.client?.current?.avatar}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 'var(--avatar-border-radius)',
          }}
        />
      </Space>
      <Space style={{ fontSize: '15px', color: '#ffffff', margin: '16px 0 0 40px' }}>
        {device?.client?.current?.displayName}
      </Space>
      <DotLoading color="primary" style={{ fontSize: '32px' }} />
    </Space>
  )
}

export default CalleeInformation
