export enum PostTypes {
  TEXT = 'application/text',
  IMAGE = 'application/jpeg',
}

export enum CALL_STATUS {
  DIALING = 'DIALING',
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  REFUSED = 'REFUSED',
  ENDED = 'ENDED',
  TIMEOUT = 'TIMEOUT',
  BUSY = 'BUSY',
}

export const DEFAULT_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/sugnee-dev-v2.appspot.com/o/images%2Favatars%2Fsugnee.png?alt=media&token=a71377c4-2592-4bd8-b669-54801e499a81'
