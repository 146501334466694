import { atomFamily } from 'recoil'
import { collection, doc, getDocs, getFirestore, onSnapshot } from 'firebase/firestore'

type ContactsParams = {
  organizationId: string
  deviceIdCurrent: string
}

export type Contact = {
  id: string
  displayName: string
  avatar: string
  callStatus: boolean
}

export const contactsSelector = atomFamily<Contact[], ContactsParams>({
  key: 'contacts',
  default: async ({ organizationId, deviceIdCurrent }) => {
    if (!organizationId) {
      return []
    }

    const devicesSnap = await getDocs(collection(getFirestore(), `organizations/${organizationId}/devices`))
    return devicesSnap.docs
      .filter((device) => device.id !== deviceIdCurrent)
      .map((deviceFiltered) => ({
        id: deviceFiltered.id,
        displayName: deviceFiltered.data().client.current.displayName || '',
        avatar: deviceFiltered.data().client.current.avatar || '',
        callStatus: deviceFiltered.data().client.current.callStatus || false,
      }))
  },
  effects: ({ organizationId, deviceIdCurrent }) => {
    return [
      ({ setSelf }) => {
        return onSnapshot(
          doc(getFirestore(), `organizations/${organizationId}/devices/${deviceIdCurrent}`),
          (currentDeviceSnap) => {
            return onSnapshot(collection(getFirestore(), `organizations/${organizationId}/devices`), (snap) => {
              const filteredContacts: Contact[] = snap.docs
                .filter((doc) => doc.id !== deviceIdCurrent)
                .map((docData) => ({
                  id: docData.id,
                  displayName: docData.data().client.current.displayName || '',
                  avatar: docData.data().client.current.avatar || '',
                  callStatus: currentDeviceSnap
                    .data()
                    ?.client.current.contacts.some((contact: Contact) => contact.id === docData.id),
                }))
              setSelf(filteredContacts)
            })
          }
        )
      },
    ]
  },
})
