import React from 'react'
import { AutoCenter, Grid, Space } from 'antd-mobile'
import { useTranslation } from 'react-i18next'

const LoginHeader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Grid.Item style={{ fontWeight: 900, fontSize: '24px', color: '#000000' }}>
        <Space block justify="center">
          {t('login.header.message1')}
        </Space>
        <Space block justify="center">
          {t('login.header.message2')}
        </Space>
      </Grid.Item>
      <Grid.Item>
        <AutoCenter style={{ fontWeight: 900, fontSize: '40px', lineHeight: '40px' }}>
          {t('login.header.title')}
        </AutoCenter>
      </Grid.Item>
      <Grid.Item>
        <Space block justify="center">
          <div
            style={{
              width: '133px',
              height: '157px',
              backgroundImage: `url('/images/sugnee.svg')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: 'var(--avatar-border-radius)',
            }}
          />
        </Space>
      </Grid.Item>
    </>
  )
}

export default LoginHeader
