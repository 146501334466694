import React, { ReactNode, Suspense, useEffect, useState } from 'react'
import { AutoCenter, FloatingBubble, Space } from 'antd-mobile'
import Header from '../../components/home/Header'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import Posts from '../../components/posts'
import { Outlet } from 'react-router-dom'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import HeaderLoading from '../../pages/loading/HeaderLoading'
import PostsLoading from '../../pages/loading/PostsLoading'
import { DeviceManagement, devicesRecoil, managementDevices } from '../../recoils/devices'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

type HomeTemplateProps = {
  deviceId: string
  organization: string
  setOrganization: (organization: string) => void
  setDeviceId: (deviceId: string) => void
  children?: ReactNode
}

const HomeTemplate: React.FC<HomeTemplateProps> = ({
  deviceId,
  organization,
  setOrganization,
  setDeviceId,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [devices, setDevices] = useRecoilState<DeviceManagement[]>(devicesRecoil(''))
  const [deviceName, setDeviceName] = useState(t('header.title.none'))

  const [isExpand, setIsExpand] = useState(false)

  useEffect(() => {
    Promise.all([managementDevices()]).then((result) => {
      setDevices(result[0])
    })
  }, [deviceId, organization, setDevices])

  useEffect(() => {
    if (deviceId && organization) {
      const db = getFirestore()
      const deviceCollection = doc(db, `/organizations/${organization}/devices/${deviceId}`)
      onSnapshot(deviceCollection, (snapshot: any) => {
        setDeviceName(snapshot.data()?.client?.current?.displayName || t('header.title.none'))
      })
    } else {
      setDeviceName(t('header.title.none'))
    }
  }, [deviceId, organization, setDeviceName, t])

  return (
    <div style={{ backgroundColor: 'var(--main-background-color)' }}>
      <div className="container">
        <Suspense fallback={<HeaderLoading />}>
          <Header
            isExpand={isExpand}
            setIsExpand={setIsExpand}
            organization={organization}
            setOrganization={setOrganization}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            deviceName={deviceName as string}
            devices={devices}
          />
        </Suspense>
        <Suspense fallback={<PostsLoading isExpand={isExpand} />}>
          <Posts isExpand={isExpand} organizationId={organization} deviceId={deviceId} devices={devices} />
        </Suspense>
        <Outlet />
        {children}
        {deviceId && (
          <FloatingBubble
            style={{
              '--initial-position-bottom': '22px',
              '--initial-position-right': '30px',
              '--edge-distance': '24px',
              '--size': '94px',
              '--background': 'var(--adm-color-primary)',
            }}
            onClick={() => navigate('/post/message')}
          >
            <Space
              align="center"
              justify="center"
              style={{ display: 'flex', flexDirection: 'column', color: '#ffffff', '--gap': '0' }}
            >
              <ChatBubbleLeftRightIcon style={{ width: '35px', margin: 0 }} />
              <AutoCenter style={{ fontSize: '12px', fontWeight: '400' }}>{t('home.button.add-post')}</AutoCenter>
            </Space>
          </FloatingBubble>
        )}
      </div>
    </div>
  )
}

export default HomeTemplate
