import React, { useState } from 'react'
import { getAuth, updateProfile, User } from '@firebase/auth'
import { Button, Input, NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { profileRecoil } from '../../../recoils/profile'
import { useTranslation } from 'react-i18next'

const EditUserDisplayName: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const { uid } = auth.currentUser as User
  const [profile, setProfile] = useRecoilState(profileRecoil(uid))
  const [displayName, setDisplayName] = useState(profile?.displayName)

  const handleEditUserDisplayName = async () => {
    await Promise.all([
      setProfile({
        ...profile,
        displayName,
      }),
      updateProfile(auth.currentUser as User, {
        displayName,
      }),
    ])
      .then(() => navigate('/user'))
      .catch((error) => {
        Toast.show({
          content: error.message,
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/user')}
      >
        {t('user.edit-display-name.title')}
      </NavBar>
      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 0' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>
          {t('user.edit-display-name.title')}
        </Space>
        <Input
          value={displayName}
          onChange={(value) => setDisplayName(value)}
          style={{ margin: '4px 0 64px', borderBottom: '1px solid #eeeeee', width: '100%' }}
        />
        <Button
          color="primary"
          fill="solid"
          loading="auto"
          style={{ width: '100%' }}
          disabled={displayName?.length < 1}
          onClick={handleEditUserDisplayName}
        >
          {t('user.edit-display-name.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditUserDisplayName
