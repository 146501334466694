import React, { useState } from 'react'
import { getAuth, updateProfile, User } from '@firebase/auth'
import { Button, ImageUploader, ImageUploadItem, NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage'
import { profileRecoil } from '../../../recoils/profile'
import { v4 as uuid } from 'uuid'
import { useTranslation } from 'react-i18next'

const EditUserAvatar: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const { uid } = auth.currentUser as User
  const [profile, setProfile] = useRecoilState(profileRecoil(uid))
  const [avatar, setAvatar] = useState<any>([])
  const [files, setFiles] = useState<ImageUploadItem[]>([{ url: profile?.avatar }])
  const [edited, setEdited] = useState(false)

  const upload = async (file: File) => {
    setAvatar(file)
    return {
      url: URL.createObjectURL(file),
    }
  }

  const handleEditUserAvatar = async () => {
    setEdited(true)
    const avatarId = uuid()

    const uploadStorage = async () => {
      const storageRef = ref(getStorage(), `images/avatars/${avatarId}`)
      const snapshot = await uploadBytes(storageRef, avatar)
      return snapshot.metadata.fullPath
    }

    Promise.all([uploadStorage()]).then((path) => {
      const getStorageUrl = async () => {
        const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
        const downloadURLs = await getDownloadURL(ref(getStorage(), url))
        return downloadURLs
      }

      Promise.all([getStorageUrl()]).then((downloadURLs) => {
        Promise.all([
          setProfile({
            ...profile,
            avatar: downloadURLs[0],
          }),
          updateProfile(auth.currentUser as User, {
            photoURL: downloadURLs[0],
          }),
        ])
          .then(() => navigate('/user'))
          .catch((error) => {
            Toast.show({
              content: error.message,
              duration: 3000,
            })
            throw new Error(error.message)
          })
      })
    })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/user')}
      >
        {t('user.edit-avatar.title')}
      </NavBar>
      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 0' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>{t('user.edit-avatar.title')}</Space>
        <ImageUploader
          value={files}
          onChange={setFiles}
          upload={upload}
          style={{ margin: '8px 0 25px' }}
          maxCount={1}
        />
        <Button
          color="primary"
          fill="solid"
          loading="auto"
          style={{ width: '100%' }}
          onClick={handleEditUserAvatar}
          disabled={edited || files.length < 1}
        >
          {t('user.edit-avatar.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditUserAvatar
