import React, { useState, useRef } from 'react'
import { Button, Dialog, List, NavBar, Skeleton, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { Outlet, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getCookie } from 'react-use-cookie'
import { TrashIcon } from '@heroicons/react/24/outline'
import { deviceRecoil } from '../../../recoils/device'
import { managersRecoil } from '../../../recoils/managers'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../utils/Utils'
import { useTranslation } from 'react-i18next'

const Managers: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const uid = getCookie('uid')
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [isDeletingDevice, setIsDeletingDevice] = useState(false)
  const [visibleDeleteManager, setVisibleDeleteManager] = useState(false)
  const device = useRecoilValue(deviceRecoil({ organizationId: organization, deviceId }))
  const managers = useRecoilValue(managersRecoil(uid))
  const managerIdRef = useRef('')

  const handleDeleteManager = () => {
    setIsDeletingDevice(true)
    const deleteManager = httpsCallable(functions, 'DeleteManager')
    deleteManager({ managerId: managerIdRef.current, deviceId })
      .then(() => {
        setIsDeletingDevice(false)
        Toast.show(`${t('device.edit.managers.message.success')}`)
      })
      .catch(() => {
        setIsDeletingDevice(false)
        Toast.show(`${t('device.edit.managers.message.error')}`)
      })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/device/detail')}
      >
        {t('device.edit.managers.title')}
      </NavBar>
      <Outlet />
      <Dialog
        visible={visibleDeleteManager}
        content={t('device.edit.managers.dialog.content')}
        closeOnAction
        onClose={() => setVisibleDeleteManager(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('device.edit.managers.dialog.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'delete',
              text: t('device.edit.managers.dialog.button.delete'),
              danger: true,
              onClick: handleDeleteManager,
            },
          ],
        ]}
      />
      <List style={{ padding: '50px 25px 0' }}>
        <Space justify="end" style={{ width: '100%', padding: '20px 0' }}>
          <Button color="primary" fill="solid" onClick={() => navigate('/device/edit/managers/add')}>
            {t('device.edit.managers.button.add')}
          </Button>
        </Space>

        {isDeletingDevice ? (
          <Skeleton.Paragraph
            lineCount={managers.filter((manager) => device.managersId?.includes(manager?.id)).length}
            animated
          />
        ) : (
          managers
            .filter((manager) => device.managersId?.includes(manager?.id))
            .map(({ displayName, id }) => (
              <List.Item
                key={id}
                extra={
                  <TrashIcon
                    onClick={() => {
                      setVisibleDeleteManager(true)
                      managerIdRef.current = id
                    }}
                    style={{ width: '16px', color: 'var(--adm-color-danger)', cursor: 'pointer' }}
                  />
                }
              >
                {displayName || t('device.edit.managers.content.none')}
              </List.Item>
            ))
        )}
      </List>
    </div>
  )
}

export default Managers
