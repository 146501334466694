import { getAuth, isSignInWithEmailLink } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { signInWithEmailLink } from '@firebase/auth'
import { Toast } from 'antd-mobile'
import { setCookie } from 'react-use-cookie'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../utils/Utils'
import HomeLoading from '../pages/loading/HomeLoading'

export const AcceptInvitation: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()

  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current && isSignInWithEmailLink(auth, window.location.href)) {
      ref.current = true
      const url = new URL(window.location.href)
      const email = url.searchParams.get('email') as string
      const inviterId = url.searchParams.get('inviterId')
      const deviceId = url.searchParams.get('deviceId')
      const acceptInvitation = httpsCallable(functions, 'AcceptInvitation')
      signInWithEmailLink(auth, email, window.location.href)
        .then(({ user }) => {
          // force refresh token
          setCookie('uid', user.uid, { path: '/' })
          acceptInvitation({ inviterId, deviceId })
            .then(() => {
              auth.currentUser?.getIdToken(true).then(() => {
                navigate('/')
              })
            })
            .catch((error) => console.log(error))
        })
        .catch(async () => {
          Toast.show({
            content: 'Error: (auth/invalid-action-code)',
            position: 'top',
            duration: 3000,
          })
          navigate('/login')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <HomeLoading />
}
