import {
  collection,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
} from 'firebase/firestore'
import { atom, atomFamily } from 'recoil'
import Post from '../entities/Post'

type PostParams = {
  organizationId: string
  deviceId: string
}

export const postsRecoil = atomFamily<Post[], PostParams>({
  key: 'posts',
  default: async ({ organizationId, deviceId }) => {
    if (!organizationId || !deviceId) {
      return []
    }
    const db = getFirestore()
    const postsQuery = query(
      collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
      orderBy('createdAt', 'desc')
    )

    const postsSnapshot = await getDocs(postsQuery)

    const posts = postsSnapshot.docs.map((post) => ({
      ...post.data(),
      id: post.id,
    }))

    return posts as Post[]
  },
  effects: ({ organizationId, deviceId }) => {
    if (!organizationId || !deviceId) {
      return []
    }
    const db = getFirestore()

    return [
      ({ setSelf }) => {
        const postsQuery = query(
          collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
          orderBy('createdAt', 'desc')
        )

        return onSnapshot(postsQuery, (snapshot) => {
          setSelf(
            snapshot.docs.map((post) => ({
              ...post.data(),
              id: post.id,
            })) as Post[]
          )
        })
      },
    ]
  },
})

export const showPostPopup = atom({
  key: 'show-post-popup',
  default: false,
})

type PostByLimitParams = {
  organizationId: string
  deviceId: string
  limitNumber: number
}

export const postsByLimit = atomFamily<Post[], PostByLimitParams>({
  key: 'posts',
  default: async ({ organizationId, deviceId, limitNumber }) => {
    if (!organizationId || !deviceId) {
      return []
    }
    const db = getFirestore()
    const postsQuery = query(
      collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
      orderBy('createdAt', 'desc'),
      limit(limitNumber)
    )

    const postsSnapshot = await getDocs(postsQuery)

    const posts = postsSnapshot.docs.map((post) => ({
      ...post.data(),
      id: post.id,
    }))

    return posts as Post[]
  },
  effects: ({ organizationId, deviceId, limitNumber }) => {
    if (!organizationId || !deviceId) {
      return []
    }
    const db = getFirestore()

    return [
      ({ setSelf }) => {
        const postsQuery = query(
          collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
          orderBy('createdAt', 'desc'),
          limit(limitNumber)
        )

        return onSnapshot(postsQuery, (snapshot) => {
          setSelf(
            snapshot.docs.map((post) => ({
              ...post.data(),
              id: post.id,
            })) as Post[]
          )
        })
      },
    ]
  },
})

type PostNextBathType = {
  lastKey: string | Timestamp
  organizationId: string
  deviceId: string
}

export const postsNextBatch = async ({ lastKey, organizationId, deviceId }: PostNextBathType) => {
  const db = getFirestore()
  const postsQuery = query(
    collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`),
    orderBy('createdAt', 'desc'),
    startAfter(lastKey),
    limit(10)
  )
  const postsSnapshot = await getDocs(postsQuery)

  const posts = postsSnapshot.docs.map((post) => ({
    ...post.data(),
    id: post.id,
  })) as Post[]

  return posts
}
