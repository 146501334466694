import React, { useState } from 'react'
import { Grid, Space, AutoCenter, NavBar, Button, Input, ImageUploader, ImageUploadItem, Toast } from 'antd-mobile'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, updateProfile, User } from '@firebase/auth'
import { doc, getFirestore, setDoc } from '@firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { getCookie } from 'react-use-cookie'
import { v4 as uuid } from 'uuid'
import { DEFAULT_AVATAR } from '../../constants'
import { useRecoilState } from 'recoil'
import { profileRecoil } from '../../recoils/profile'
import { useTranslation } from 'react-i18next'

const RegisterUser: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const db = getFirestore()
  const uid = getCookie('uid')
  const navigate = useNavigate()
  const [profile, setProfile] = useRecoilState(profileRecoil(uid))
  const [files, setFiles] = useState<ImageUploadItem[]>([
    {
      url: profile?.avatar || DEFAULT_AVATAR,
    },
  ])

  const [avatar, setAvatar] = useState<any>([])

  const [displayName, setDisplayName] = useState(profile?.displayName)

  const upload = async (file: File) => {
    setAvatar(file)
    return {
      url: URL.createObjectURL(file),
    }
  }

  const handleCreateProfile = async () => {
    const avatarId = uuid()

    const uploadStorage = async () => {
      if (avatar.length === 0) {
        return
      }
      const storageRef = ref(getStorage(), `images/avatars/${avatarId}`)
      const snapshot = await uploadBytes(storageRef, avatar)
      return snapshot.metadata.fullPath
    }

    await Promise.all([uploadStorage()])
      .then(async (path) => {
        const getStorageUrl = async () => {
          if (!path[0]) {
            return profile?.avatar || DEFAULT_AVATAR
          }
          const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
          const downloadURLs = await getDownloadURL(ref(getStorage(), url))
          return downloadURLs
        }

        await Promise.all([getStorageUrl()]).then(async (downloadURLs) => {
          const updateFirestore = () => {
            if (profile) {
              setProfile({ ...profile, avatar: downloadURLs[0], displayName })
              return
            }

            setDoc(doc(db, `/organizations`, uid), {
              avatar: downloadURLs[0],
              displayName,
            })
          }

          await Promise.all([
            updateProfile(auth.currentUser as User, {
              photoURL: downloadURLs[0],
              displayName,
            }),
            updateFirestore(),
          ])
            .then(() => navigate('/'))
            .catch((error) => {
              Toast.show({
                content: error.message,
                duration: 3000,
              })
              throw new Error(error.message)
            })
        })
      })
      .catch((error) => {
        Toast.show({
          content: error.message,
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  return (
    <div className="container" style={{ backgroundColor: 'var(--main-background-color)', padding: '10px 20px 60px' }}>
      <Link to="/register/confirm">
        <NavBar style={{ color: '#ffffff' }} />
      </Link>
      <Grid
        columns={1}
        gap="24px"
        style={{
          borderRadius: 'var(--wrapper-border-radius)',
          backgroundColor: '#ffffff',
          padding: '32px 15px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Grid.Item>
          <AutoCenter style={{ fontSize: '18px', fontWeight: 700, color: '#000000' }}>
            {t('register-user.header')}
          </AutoCenter>
        </Grid.Item>
        <Grid.Item>
          <Space block justify="center">
            <div
              style={{
                width: '133px',
                height: '157px',
                backgroundImage: `url('/images/sugnee.svg')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 'var(--avatar-border-radius)',
              }}
            />
          </Space>
        </Grid.Item>
        <Grid.Item style={{ color: '#000000', fontSize: '16px', fontWeight: '400' }}>
          <Space>{t('register-user.message1')}</Space>
          <Space wrap style={{ display: 'block' }}>
            {t('register-user.message2')}
          </Space>
        </Grid.Item>
        <Grid.Item style={{ display: 'flex', flexDirection: 'column', padding: '12px 0' }}>
          <Space style={{ fontSize: '15px', fontWeight: 400, color: '#666666' }}>{t('register-user.avatar')}</Space>
          <ImageUploader value={files} onChange={setFiles} upload={upload} maxCount={1} />
        </Grid.Item>
        <Grid.Item style={{ borderBottom: '1px solid #EEEEEE', padding: '12px 0' }}>
          <Space style={{ fontSize: '15px', fontWeight: 400, color: '#666666', marginBottom: '4px' }}>
            {t('register-user.display-name')}
          </Space>
          <Input placeholder="スグニー 太郎" value={displayName} onChange={(value) => setDisplayName(value)} />
        </Grid.Item>
        <Grid.Item>
          <Button
            color="primary"
            loading="auto"
            style={{ width: '100%', height: '40px', borderRadius: 'var(--adm-button-border-radius)' }}
            disabled={displayName?.length === 0}
            onClick={handleCreateProfile}
          >
            {t('register-user.button.registration')}
          </Button>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default RegisterUser
