import React from 'react'
import { Space } from 'antd-mobile'
import { useRecoilValue } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { useTranslation } from 'react-i18next'

type DeviceInformationProps = {
  organizationId: string
  deviceId: string
}

const DeviceInformation: React.FC<DeviceInformationProps> = ({ organizationId, deviceId }) => {
  const { t } = useTranslation()
  const deviceCurrent = useRecoilValue(deviceRecoil({ organizationId, deviceId }))
  const { avatar, displayName } = deviceCurrent?.client?.current

  return (
    <>
      <div
        style={{
          width: '70px',
          height: '70px',
          backgroundImage: `url('${avatar}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: 'var(--avatar-border-radius)',
        }}
      />
      <Space style={{ fontSize: '12px', fontWeight: '400', color: '#4E4E4E' }}>
        <>{t('posts.create.device-information', { displayName })}</>
      </Space>
    </>
  )
}

export default DeviceInformation
