import React, { useState } from 'react'
import { Button, Input, NavBar, Space } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { deviceRecoil } from '../../../recoils/device'
import { getCookie } from 'react-use-cookie'
import { useTranslation } from 'react-i18next'

const EditDeviceDisplayName: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId: organization, deviceId }))
  const [displayName, setDisplayName] = useState(device.client.current.displayName)

  const handleEditDeviceDisplayName = () => {
    setDevice({
      ...device,
      client: { ...device.client, current: { ...device.client.current, displayName } },
    })
    navigate('/device/detail')
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/device/detail')}
      >
        {t('device.edit.display-name.title')}
      </NavBar>
      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 20px' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>
          {t('device.edit.display-name.title')}
        </Space>
        <Input
          value={displayName}
          onChange={(value) => setDisplayName(value)}
          style={{ margin: '4px 0 64px', borderBottom: '1px solid #eeeeee', width: '100%' }}
        />
        <Button
          color="primary"
          fill="solid"
          style={{ width: '100%' }}
          disabled={displayName.length < 1}
          onClick={handleEditDeviceDisplayName}
        >
          {t('device.edit.display-name.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditDeviceDisplayName
