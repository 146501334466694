import React, { useState } from 'react'
import { Button, Dialog, List, NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import useCookie, { getCookie } from 'react-use-cookie'
import { useRecoilValue } from 'recoil'
import { CALL_BUTTON_STATUS, LIKE_BUTTON_STATUS } from '../../entities/Device'
import { deviceRecoil } from '../../recoils/device'
import { devicesRecoil, managementDevices } from '../../recoils/devices'
import { collection, deleteDoc, doc, getDocs, getFirestore } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'

const DeviceDetail: React.FC = () => {
  const { t } = useTranslation()
  const db = getFirestore()
  const navigate = useNavigate()
  const uid = getCookie('uid')
  const [organization, setOrganization] = useCookie('organization')
  const [deviceId, setDeviceId] = useCookie('deviceId')
  const devices = useRecoilValue(devicesRecoil(''))
  const device = useRecoilValue(deviceRecoil({ organizationId: organization, deviceId }))
  const [visibleDeleteDevice, setVisibleDeleteDevice] = useState(false)

  const handleDeleteDevice = () => {
    deleteDoc(doc(db, `/organizations/${uid}/devices`, deviceId))
      .then(async () => {
        Toast.show({
          content: t('device-detail.delete.message.success'),
          position: 'top',
          duration: 3000,
        })
        const posts = await getDocs(collection(getFirestore(), `organizations/${uid}/devices/${deviceId}/posts`))
        await Promise.all(
          posts.docs.map(async (data) => {
            await deleteDoc(data.ref)
          })
        )

        const devices = await managementDevices()
        if (devices.length === 0) {
          setDeviceId('')
          setOrganization('')
        } else {
          setDeviceId(devices[0]?.data?.id)
          setOrganization(devices[0].organization)
        }

        navigate('/')
      })
      .catch((error) => {
        Toast.show({
          content: t('device-detail.delete.message.error'),
          position: 'top',
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', '--height': '55px' }}
        backArrow={false}
        right={
          <Button color="primary" fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="textTruncateMaxWidth">
            {device ? device?.client?.current?.displayName : t('device-detail.title.none')}
          </div>
        </div>
      </NavBar>
      <Space style={{ padding: '67px 12px 12px', fontSize: '15px', fontWeight: '700', color: '#4E4E4E' }}>
        {t('device-detail.information')}
      </Space>
      <List header={t('device-detail.avatar')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/device/edit/avatar')}>
          <div
            style={{
              width: '80px',
              height: '80px',
              backgroundImage: `url('${device?.client?.current?.avatar}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: 'var(--avatar-border-radius)',
            }}
          />
        </List.Item>
      </List>

      <List header={t('device-detail.display-name')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/device/edit/display-name')}>
          {device?.client?.current?.displayName}
        </List.Item>
      </List>

      <List header={t('device-detail.name')} style={{ '--border-bottom': 'none', '--border-top': 'none' }}>
        <List.Item onClick={() => navigate('/device/edit/name')}>{device?.client?.current?.name}</List.Item>
      </List>

      <Space style={{ fontSize: '15px', fontWeight: '700', color: '#4E4E4E', padding: '78px 12px 26px' }}>
        {t('device-detail.settings')}
      </Space>

      <List style={{ '--border-top': 'none' }}>
        <List.Item
          extra={
            device?.client?.current?.callButtonStatus === CALL_BUTTON_STATUS.SHOW
              ? t('device-detail.call-status.show')
              : t('device-detail.call-status.hide')
          }
          onClick={() => navigate('/device/edit/call-button')}
        >
          {t('device-detail.call-status')}
        </List.Item>

        <List.Item
          extra={
            device?.client?.current?.likeButtonStatus === LIKE_BUTTON_STATUS.SHOW
              ? t('device-detail.like-status.show')
              : t('device-detail.like-status.hide')
          }
          onClick={() => navigate('/device/edit/like-button')}
        >
          {t('device-detail.like-status')}
        </List.Item>

        <List.Item
          extra={`${device?.client?.current?.contacts?.length || 0}${t('device-detail.people')}`}
          onClick={() => navigate('/device/edit/contacts')}
        >
          {t('device-detail.contact')}
        </List.Item>
        {devices.find((device) => device.data.id === deviceId)?.role === 'owner' && (
          <List.Item
            extra={`${device?.managersId?.length || 0}${t('device-detail.people')}`}
            onClick={() => navigate('/device/edit/managers')}
          >
            {t('device-detail.manager')}
          </List.Item>
        )}
      </List>
      <Dialog
        visible={visibleDeleteDevice}
        content={t('device-detail.dialog.content')}
        closeOnAction
        onClose={() => setVisibleDeleteDevice(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('device-detail.dialog.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'delete',
              text: t('device-detail.dialog.button.delete'),
              danger: true,
              onClick: handleDeleteDevice,
            },
          ],
        ]}
      />
      {devices.find((device) => device.data.id === deviceId)?.role === 'owner' && (
        <Button
          style={{
            margin: '48px 20px 10px',
            color: 'var(--adm-color-danger)',
            border: '1px solid var(--adm-color-danger)',
          }}
          onClick={() => setVisibleDeleteDevice(true)}
        >
          {t('device-detail.button.delete')}
        </Button>
      )}
    </div>
  )
}

export default DeviceDetail
