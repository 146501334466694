import React, { useEffect, useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { AutoCenter, Button, CenterPopup, Form, Grid, Input, Space, SpinLoading, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router'
import { setCookie } from 'react-use-cookie'
import { functions } from '../../utils/Utils'
import { getAuth } from '@firebase/auth'
import { getAnalytics, logEvent } from '@firebase/analytics'
import QrReader from 'react-qr-reader'
import { useTranslation } from 'react-i18next'

const ERROR_LIMIT = 'functions/failed-precondition'

const convertString = (value: string) => {
  return value
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    .toUpperCase()
}

const InputPassCode: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showInputPassCode, setShowInputPassCode] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [passcode, setPasscode] = useState('')
  const analytics = getAnalytics()
  const [isQRCode, setIsQRCode] = useState(false)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setShowInputPassCode(true)
  }, [])

  const handleActivePassCode = async (passcode: string) => {
    const auth = getAuth()
    setShowInputPassCode(false)
    setShowLoading(true)
    const activateDevice = httpsCallable(functions, 'ActivateDevice')
    const identifier = convertString(passcode.slice(0, 4))
    const secret = convertString(passcode.slice(4, 8))

    activateDevice({ identifier, secret })
      .then((result: any) => {
        logEvent(analytics, 'register_device')
        const { deviceId } = result.data
        setCookie('deviceId', deviceId, { path: '/' })
        setCookie('organization', auth.currentUser?.uid as string, { path: '/' })
        navigate('/input-device')
      })
      .catch((error) => {
        if (error.code === ERROR_LIMIT) {
          Toast.show({
            content: t('input-passcode.message.limit'),
            position: 'top',
            duration: 3000,
          })
        } else {
          Toast.show({
            content: t('input-passcode.message.error'),
            position: 'top',
            duration: 3000,
          })
        }

        setShowInputPassCode(true)
        setShowLoading(false)
      })
  }

  const handleScan = (data: any) => {
    if (data && !isActive) {
      setIsActive(true)
      setShowInputPassCode(false)
      setShowLoading(true)
      handleActivePassCode(data.replace('-', ''))
    }
  }

  return (
    <>
      <CenterPopup visible={showInputPassCode} onMaskClick={() => navigate('/')}>
        <Grid
          columns={1}
          gap={18}
          style={{ padding: '20px 12px', width: '100%', boxSizing: 'border-box', position: 'relative' }}
        >
          <Grid.Item>
            <AutoCenter style={{ fontSize: '15px', fontWeight: '700' }}>{t('input-passcode.title')}</AutoCenter>
          </Grid.Item>
          {isQRCode ? (
            <>
              <Grid.Item>
                <QrReader delay={300} onError={() => {}} onScan={handleScan} style={{ height: 240, width: 240 }} />
              </Grid.Item>
              <Grid.Item>
                <AutoCenter>
                  <Button color="primary" fill="none" size="small" onClick={() => setIsQRCode(false)}>
                    ＜ {t('input-passcode.button.key-board')}
                  </Button>
                </AutoCenter>
              </Grid.Item>
            </>
          ) : (
            <>
              <Grid.Item style={{ fontSize: '14px', fontWeight: '400' }}>
                <AutoCenter>{t('input-passcode.message1')}</AutoCenter>
                <AutoCenter>{t('input-passcode.message2')}</AutoCenter>
              </Grid.Item>
              <Grid.Item>
                <Form layout="vertical" style={{ '--border-top': 'none' }}>
                  <Form.Item label={t('input-passcode.title.passcode')} style={{ padding: '0 0 0 5px' }}>
                    <Input
                      value={passcode}
                      onChange={(value: string) => setPasscode(value)}
                      type="text"
                      inputMode="url"
                      maxLength={8}
                      placeholder={t('input-passcode.passcode.placeholder') || 'パスコードを入力してください'}
                      clearable
                      autoFocus
                    />
                  </Form.Item>
                </Form>
              </Grid.Item>
              <Grid.Item>
                <Button
                  color="primary"
                  fill="none"
                  size="small"
                  style={{ margin: '0 auto', display: 'block' }}
                  onClick={() => setIsQRCode(true)}
                >
                  {t('input-passcode.button.qr')}
                </Button>
                <Button
                  color="primary"
                  style={{ width: '100%', fontSize: '18px', fontWeight: '400', marginTop: '10px' }}
                  disabled={passcode.length !== 8}
                  onClick={() => handleActivePassCode(passcode)}
                >
                  {t('input-passcode.button.register')}
                </Button>
              </Grid.Item>
            </>
          )}
        </Grid>
      </CenterPopup>
      <CenterPopup visible={showLoading}>
        <Grid columns={1} gap={24} style={{ padding: '20px 12px 33px', width: '100%', boxSizing: 'border-box' }}>
          <Grid.Item>
            <AutoCenter style={{ fontSize: '15px', fontWeight: '700' }}>{t('input-passcode.loading.title')}</AutoCenter>
          </Grid.Item>
          <Grid.Item style={{ display: 'flex', flexDirection: 'column', fontSize: '14px', fontWeight: '400' }}>
            <Space>{t('input-passcode.loading.message1')}</Space>
            <Space>{t('input-passcode.loading.message2')}</Space>
          </Grid.Item>
          <Grid.Item>
            <AutoCenter>
              <SpinLoading color="primary" style={{ width: '146px', height: '146px' }} />
            </AutoCenter>
          </Grid.Item>
        </Grid>
      </CenterPopup>
    </>
  )
}

export default InputPassCode
