import React, { useState } from 'react'
import { Button, Grid, Space, AutoCenter, Input, Form, Toast, Dialog } from 'antd-mobile'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, sendSignInLinkToEmail } from '@firebase/auth'
import { setCookie } from 'react-use-cookie'
import { functions, isValidEmail } from '../../utils/Utils'
import LoginHeader from '../../components/LoginHeader'
import { httpsCallable } from 'firebase/functions'
import Loading from '../loading/Loading'
import { useTranslation } from 'react-i18next'
import { LANGUAGE, languageCurrentRecoil } from '../../recoils/language'
import { useRecoilState } from 'recoil'
import { Select } from 'antd'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [accountExist, setAccountExist] = useState(true)
  const [loginError, setLoginError] = useState(false)
  const [openActivateAccount, setOpenActivateAccount] = useState(false)
  const [isActivatingAccount, setIsActivatingAccount] = useState(false)
  const [languageCurrent, setLanguageCurrent] = useRecoilState(languageCurrentRecoil)

  const handleLogin = async () => {
    const checkAccountLogin = httpsCallable(functions, 'Login')
    await checkAccountLogin({ email })
      .then(async () => {
        setAccountExist(true)
        const url = `${window?.location.origin}/login/callback/?email=${email}`
        const actionCodeSettings = {
          url: url,
          handleCodeInApp: true,
        }
        auth.languageCode = languageCurrent
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            setCookie('email', email, { path: '/' })
            navigate('/login/confirm')
            setLoginError(false)
          })
          .catch((error) => {
            Toast.show({
              content: error.message,
              position: 'top',
              duration: 3000,
            })
            setLoginError(true)
            throw new Error(error.message)
          })
      })
      .catch((error) => {
        if (error?.message?.includes('disabled')) {
          setOpenActivateAccount(true)
        }
        if (error?.message?.includes('Email does not exist')) {
          setAccountExist(false)
        }
      })
  }

  const handleActivateDevice = () => {
    setIsActivatingAccount(true)
    const reactivateAccount = httpsCallable(functions, 'ReactivateAccount')
    reactivateAccount({ email })
      .then(() => {
        Toast.show({
          content: t('login.dialog.message.success'),
          position: 'top',
          duration: 3000,
        })
        setAccountExist(true)
      })
      .catch(() => {
        Toast.show({
          content: t('login.dialog.message.error'),
          position: 'top',
          duration: 3000,
        })
      })
      .finally(() => {
        setIsActivatingAccount(false)
      })
  }
  return (
    <div
      className="container"
      style={{ backgroundColor: 'var(--main-background-color)', padding: '60px 20px 80px', position: 'relative' }}
    >
      <Select
        labelInValue
        style={{ position: 'absolute', top: '25px', right: '30px' }}
        defaultValue={{
          value: languageCurrent,
          label: languageCurrent === LANGUAGE.JAPANESE.VALUE ? LANGUAGE.JAPANESE.NAME : LANGUAGE.ENGLISH.NAME,
        }}
        onChange={({ value }) => setLanguageCurrent(value)}
        options={[
          {
            value: LANGUAGE.JAPANESE.VALUE,
            label: LANGUAGE.JAPANESE.NAME,
          },
          {
            value: LANGUAGE.ENGLISH.VALUE,
            label: LANGUAGE.ENGLISH.NAME,
          },
        ]}
      />
      {isActivatingAccount ? (
        <Loading />
      ) : (
        <Grid columns={1} gap={15}>
          <LoginHeader />
          <Form
            style={{
              borderRadius: 'var(--wrapper-border-radius)',
              boxShadow: 'var(--box-shadow)',
              padding: ' 10px 10px 15px',
              backgroundColor: '#ffffff',
              '--border-top': 'none',
              '--border-bottom': 'none',
              marginTop: '16px',
            }}
          >
            <Form.Header>
              <AutoCenter style={{ fontSize: '20px', fontWeight: 400, color: '#000000' }}>
                {t('login.form.header')}
              </AutoCenter>
            </Form.Header>
            <Form.Item label={t('login.form.email')}>
              <Input onChange={(value) => setEmail(value)} type="email" placeholder="example@sugnee.jp" />
              {!accountExist && (
                <Space style={{ fontSize: '13px', color: '#FF3141' }}>{t('login.message.not-exist')}</Space>
              )}
              {accountExist && loginError && (
                <Space style={{ fontSize: '13px', color: '#FF3141' }}>{t('login.message.error')}</Space>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                color="primary"
                disabled={!isValidEmail(email)}
                style={{ width: '100%', height: '40px' }}
                onClick={handleLogin}
                loading="auto"
              >
                {t('login.form.button-login')}
              </Button>
            </Form.Item>
            <Link to="/register">
              <Space block justify="end" style={{ fontWeight: 400, fontSize: '14px' }}>
                <Button color="primary" fill="none" style={{ textDecoration: 'underline' }}>
                  {t('login.form.button-register')}
                </Button>
              </Space>
            </Link>
          </Form>
        </Grid>
      )}

      <Dialog
        visible={openActivateAccount}
        header={<div style={{ fontSize: '16px', color: '#000000' }}>{t('login.dialog.header')}</div>}
        content={
          <div style={{ maxWidth: '250px', margin: 'auto', padding: '10px 0' }}>{t('login.dialog.content')}</div>
        }
        closeOnAction
        bodyStyle={{ maxWidth: '400px' }}
        onClose={() => setOpenActivateAccount(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('login.dialog.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'restart',
              text: t('login.dialog.button.restart'),
              style: { color: '#000000', fontWeight: 'bold' },
              onClick: handleActivateDevice,
            },
          ],
        ]}
      />
    </div>
  )
}

export default Login
