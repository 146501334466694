import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'
import { atomFamily } from 'recoil'
import Device from '../entities/Device'

type DeviceParams = {
  organizationId: string
  deviceId: string
}

export const deviceRecoil = atomFamily<Device, DeviceParams>({
  key: 'device',
  default: async ({ organizationId, deviceId }) => {
    const db = getFirestore()
    const deviceRef = doc(db, `/organizations/${organizationId}/devices/${deviceId || 'default'}`)
    const snapshot = await getDoc(deviceRef)

    return snapshot.data() as Device
  },
  effects: ({ organizationId, deviceId }) => {
    const db = getFirestore()
    return [
      ({ setSelf }) => {
        const deviceRef = doc(db, `/organizations/${organizationId}/devices/${deviceId || 'default'}`)
        return onSnapshot(deviceRef, (snapshot) => {
          setSelf(snapshot.data() as Device)
        })
      },
      ({ onSet }) => {
        const deviceRef = doc(db, `/organizations/${organizationId}/devices/${deviceId}`)
        return onSet((newValue) => {
          if (newValue) {
            return updateDoc(deviceRef, newValue)
          }
        })
      },
    ]
  },
})
