import React, { Suspense, useEffect, useState } from 'react'
import { getAuth, User } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { PostTypes } from '../../constants'
import DeviceInformation from './DeviceInformation'
import { Button, Grid, ImageUploader, ImageUploadItem, NavBar, Popup, Space, Tabs, TextArea, Toast } from 'antd-mobile'
import { getCookie } from 'react-use-cookie'
import { useRecoilState, useRecoilValue } from 'recoil'
import { postsRecoil, showPostPopup } from '../../recoils/posts'
import { v4 as uuid } from 'uuid'
import { getAnalytics, logEvent } from '@firebase/analytics'
import { organizationRecoil } from '../../recoils/organization'
import { useTranslation } from 'react-i18next'

const CreatePost: React.FC<{ postType: PostTypes }> = ({ postType }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const organizationId = getCookie('organization')
  const { uid, photoURL, displayName } = auth.currentUser as User
  const db = getFirestore()
  const [files, setFiles] = useState<ImageUploadItem[]>([])
  const [postImage, setPostImage] = useState<any>([])
  const [postMessage, setPostMessage] = useState('')
  const [postDescription, setPostDescription] = useState('')
  const deviceId = getCookie('deviceId')
  const [showPopup, setShowPopup] = useRecoilState(showPostPopup)
  const [posted, setPosted] = useState(false)
  const analytics = getAnalytics()
  const organization = useRecoilValue(organizationRecoil(uid))
  const posts = useRecoilValue(postsRecoil({ organizationId, deviceId }))

  useEffect(() => {
    setShowPopup(true)

    return () => {
      setShowPopup(false)
    }
  }, [setShowPopup])

  const handlePost = async () => {
    if (organization?.limit && posts?.length >= organization?.limit?.posts) {
      Toast.show({
        content: t('posts.create.message.limit'),
        duration: 3000,
      })
      return
    }
    logEvent(analytics, 'create_post')
    setPosted(true)
    const uploadStorage = async () => {
      if (postType === PostTypes.TEXT) {
        return
      }
      const postImageId = uuid()
      const storageRef = ref(getStorage(), `images/post/${postImageId}`)
      const snapshot = await uploadBytes(storageRef, postImage)
      return snapshot.metadata.fullPath
    }

    await Promise.all([uploadStorage()]).then((path) => {
      const getContent = async () => {
        if (postType === PostTypes.TEXT) {
          return postMessage
        }

        const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
        const downloadURL = await getDownloadURL(ref(getStorage(), url))
        return downloadURL
      }

      Promise.all([getContent()]).then((contents) => {
        const post = {
          content: contents[0],
          type: postType,
          description: postType === PostTypes.IMAGE ? postDescription : '',
          createdByUser: {
            id: uid,
            avatar: photoURL,
            displayName: displayName,
          },
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        }

        addDoc(collection(db, `/organizations/${organizationId}/devices/${deviceId}/posts`), post)
          .then(() => {
            Toast.show({
              content: '完了',
              duration: 3000,
              afterClose: () => navigate('/'),
            })
          })
          .catch((error) => {
            Toast.show({
              content: error.message,
              duration: 3000,
              afterClose: () => navigate('/'),
            })
            throw new Error(error.message)
          })
        window.scrollTo(0, 0)
      })
    })
  }

  const upload = async (file: File) => {
    setPostImage(file)
    return {
      url: URL.createObjectURL(file),
    }
  }
  return (
    <Popup
      visible={showPopup}
      onMaskClick={() => {
        setShowPopup(false)
        navigate('/')
      }}
      bodyStyle={{ height: '80vh' }}
    >
      <NavBar
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        left={
          <Button
            color="primary"
            fill="none"
            style={{ borderRadius: '0', fontSize: '14px', padding: '3px 0 0 0' }}
            disabled={posted}
            onClick={() => navigate('/')}
          >
            {t('posts.create.button.cancel')}
          </Button>
        }
        right={
          <Button
            color="primary"
            fill="none"
            style={{ borderRadius: '0', fontSize: '14px', padding: '3px 12px 0' }}
            loading="auto"
            disabled={
              posted || !deviceId || (postType === PostTypes.TEXT ? postMessage.length === 0 : postImage.length === 0)
            }
            onClick={handlePost}
          >
            {t('posts.create.button.post')}
          </Button>
        }
        onBack={() => navigate('/')}
        backArrow={false}
      >
        <Space style={{ color: '#333333', fontSize: '15px' }}>{t('posts.create.title')}</Space>
      </NavBar>
      <Tabs
        activeLineMode="full"
        defaultActiveKey={postType}
        onChange={(key) => navigate(`/post/${key === PostTypes.TEXT ? 'message' : 'image'}`)}
      >
        <Tabs.Tab
          title={t('posts.create.tabs.message')}
          key={PostTypes.TEXT}
          style={{ width: '50%' }}
          disabled={posted}
        >
          <Grid columns={1} gap={36}>
            <Grid.Item>
              {deviceId && (
                <Suspense>
                  <DeviceInformation organizationId={organizationId} deviceId={deviceId} />
                </Suspense>
              )}
            </Grid.Item>
            <Grid.Item>
              <TextArea
                defaultValue={postMessage}
                showCount
                maxLength={15}
                placeholder={t('posts.create.content.placeholder') || 'ここにメッセージを入力してください。'}
                onChange={(value) => setPostMessage(value)}
                disabled={posted}
              />
              {postMessage.length > 15 && (
                <Space style={{ fontSize: '13px', fontWeight: '400', color: '#FF3141' }}>
                  {t('posts.create.message.content-max-length')}
                </Space>
              )}
            </Grid.Item>
          </Grid>
        </Tabs.Tab>
        <Tabs.Tab title={t('posts.create.tabs.image')} key={PostTypes.IMAGE} style={{ width: '50%' }} disabled={posted}>
          <Grid columns={1} gap={36}>
            <Grid.Item>
              {deviceId && <DeviceInformation organizationId={organizationId} deviceId={deviceId} />}
            </Grid.Item>
            <Grid.Item>
              <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666', marginBottom: '8px' }}>
                {t('posts.create.title.image')}
              </Space>
              <ImageUploader
                value={files}
                onChange={posted ? () => {} : setFiles}
                upload={upload}
                maxCount={1}
                disableUpload={posted}
              />
            </Grid.Item>
            <Grid.Item>
              <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666', marginBottom: '8px' }}>
                {t('posts.create.message.description-title')}
              </Space>
              <TextArea
                defaultValue={postDescription}
                showCount
                maxLength={30}
                placeholder={t('posts.create.description.placeholder') || 'ここにタイトルを入力してください。'}
                onChange={(value) => setPostDescription(value)}
                disabled={posted}
              />
              {postDescription.length > 30 && (
                <Space style={{ fontSize: '13px', fontWeight: '400', color: '#FF3141' }}>
                  {t('posts.create.message.description-max-length')}
                </Space>
              )}
            </Grid.Item>
          </Grid>
        </Tabs.Tab>
      </Tabs>
    </Popup>
  )
}

export default CreatePost
