import React, { useState } from 'react'
import { Button, Input, NavBar, Space } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { deviceRecoil } from '../../../recoils/device'
import { getCookie } from 'react-use-cookie'
import { useTranslation } from 'react-i18next'

const EditDeviceName: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId: organization, deviceId }))
  const [name, setName] = useState(device.client.current.name)

  const handleEditDeviceName = () => {
    setDevice({
      ...device,
      client: { ...device.client, current: { ...device.client.current, name } },
    })
    navigate('/device/detail')
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/device/detail')}
      >
        {t('device.edit.name.title')}
      </NavBar>
      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 20px' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>{t('device.edit.name.title')}</Space>
        <Input
          value={name}
          onChange={(value) => setName(value)}
          style={{ margin: '4px 0 64px', borderBottom: '1px solid #eeeeee', width: '100%' }}
        />
        <Button
          color="primary"
          fill="solid"
          style={{ width: '100%' }}
          disabled={name.length < 1}
          onClick={handleEditDeviceName}
        >
          {t('device.edit.name.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditDeviceName
