import { getFunctions } from '@firebase/functions'

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const convertTimestampToDate = (dateTimeNumber: number) => {
  if (!dateTimeNumber) return ''

  const dateTimeByLocal = new Date(dateTimeNumber * 1000)

  const year = dateTimeByLocal.getFullYear()
  const month = dateTimeByLocal.getMonth() < 9 ? `0${dateTimeByLocal.getMonth() + 1}` : dateTimeByLocal.getMonth() + 1
  const date = dateTimeByLocal.getDate() < 10 ? `0${dateTimeByLocal.getDate()}` : dateTimeByLocal.getDate()

  return `${year}/${month}/${date}`
}

export const functions = getFunctions(undefined, 'asia-northeast2')
