import React, { useState } from 'react'
import { Button, Dialog, ImageViewer, Popover, Space, Toast } from 'antd-mobile'
import { UserOutline } from 'antd-mobile-icons'
import PostProps from '../../entities/Post'
import { convertTimestampToDate } from '../../utils/Utils'
import { PostTypes } from '../../constants'
import { EllipsisHorizontalIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { getCookie } from 'react-use-cookie'
import { Action } from 'antd-mobile/es/components/popover'
import { useNavigate } from 'react-router-dom'
import { deleteObject, getStorage, ref } from 'firebase/storage'
import { HeartIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

const KEY = {
  EDIT: 'edit',
  DELETE: 'delete',
}

const Post: React.FC<{ post: PostProps }> = ({ post }) => {
  const { t } = useTranslation()
  const db = getFirestore()
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [visibleDeletePost, setVisibleDeletePost] = useState(false)
  const navigate = useNavigate()
  const [visibleImageViewer, setVisibleImageViewer] = useState(false)

  const actionsPopover: Action[] = [
    {
      key: KEY.EDIT,
      icon: <PencilIcon style={{ width: '16px', marginTop: '6px' }} />,
      text: t('posts.detail.popover.button.edit'),
    },
    {
      key: KEY.DELETE,
      icon: <XMarkIcon style={{ width: '18px', marginTop: '6px' }} />,
      text: t('posts.detail.popover.button.delete'),
    },
  ]

  const handleDeletePost = () => {
    deleteDoc(doc(db, `/organizations/${organization}/devices/${deviceId}/posts`, post.id))
      .then(() => {
        if (post.type === PostTypes.IMAGE) {
          //Delete image on storage
          deleteObject(ref(getStorage(), post.content))
        }
        Toast.show({
          content: t('posts.delete.message.success'),
          position: 'top',
          duration: 3000,
        })
      })
      .catch((error) => {
        Toast.show({
          content: t('posts.delete.message.failed'),
          position: 'top',
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  const handleByActionPost = (node: any) => {
    node.key === KEY.DELETE ? setVisibleDeletePost(true) : navigate(`/post/edit/${post.id}`)
  }

  return (
    <div
      style={{
        marginBottom: '24px',
        fontWeight: '400',
        color: '#000000',
        backgroundColor: '#ffffff',
        boxShadow: 'var(--box-shadow)',
        borderRadius: 'var(--wrapper-border-radius)',
      }}
    >
      <Dialog
        visible={visibleDeletePost}
        content={t('posts.delete.message.confirm')}
        closeOnAction
        onClose={() => setVisibleDeletePost(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('posts.delete.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'delete',
              text: t('posts.delete.button.delete'),
              danger: true,
              onClick: handleDeletePost,
            },
          ],
        ]}
      />

      <Space
        justify="center"
        align="center"
        style={{
          display: 'flex',
          width: '100%',
          height: '174px',
          backgroundColor: 'var(--sub-background-color)',
          borderRadius: 'var(--wrapper-border-radius) var(--wrapper-border-radius) 0 0',
          position: 'relative',
        }}
      >
        <Popover.Menu
          actions={actionsPopover}
          placement="bottom-end"
          onAction={(node) => handleByActionPost(node)}
          trigger="click"
        >
          <Button
            fill="none"
            style={{
              position: 'absolute',
              borderRadius: 'var(--adm-button-border-radius)',
              top: '16px',
              right: '13px',
              padding: '0',
              margin: '0',
              width: '34px',
              height: '34px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <EllipsisHorizontalIcon style={{ width: '16px', color: '#ffffff', marginTop: '6px' }} />
          </Button>
        </Popover.Menu>
        {post?.like && (
          <HeartIcon style={{ position: 'absolute', width: '40px', bottom: '6px', right: '13px', color: '#2BC16B' }} />
        )}
        {post.type === PostTypes.TEXT ? (
          <Space style={{ fontSize: '20px' }}>{post.content}</Space>
        ) : (
          <>
            <div
              style={{
                width: '336px',
                height: '174px',
                backgroundImage: `url('${post.content}')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 'var(--avatar-border-radius)',
              }}
              onClick={() => setVisibleImageViewer(true)}
            />
            <ImageViewer
              image={post.content}
              visible={visibleImageViewer}
              onClose={() => setVisibleImageViewer(false)}
            />
          </>
        )}
      </Space>
      <Space
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '15px 17px',
          boxSizing: 'border-box',
        }}
      >
        {post.type === PostTypes.IMAGE && (
          <Space wrap style={{ fontSize: '15px', display: 'block' }}>
            {post.description}
          </Space>
        )}
        <Space justify="between" style={{ width: '100%', fontSize: '15px', marginTop: '16px' }}>
          <Space align="center">
            <UserOutline fontSize={20} />
            {post.createdByUser.displayName}
          </Space>
          <Space>{convertTimestampToDate(post.createdAt.seconds)}</Space>
        </Space>
      </Space>
    </div>
  )
}

export default Post
