import React, { useState } from 'react'
import { AutoCenter, Button, CenterPopup, Form, Grid, ImageUploader, ImageUploadItem, Input, Space } from 'antd-mobile'
import { useNavigate } from 'react-router'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { Timestamp } from '@firebase/firestore'
import { getCookie } from 'react-use-cookie'
import { useRecoilState } from 'recoil'
import { deviceRecoil } from '../../recoils/device'
import { v4 as uuid } from 'uuid'
import { DEFAULT_AVATAR } from '../../constants'
import { useTranslation } from 'react-i18next'

const InputDevice: React.FC = () => {
  const { t } = useTranslation()
  const uid = getCookie('uid')
  const navigate = useNavigate()
  const [deviceAvatar, setDeviceAvatar] = useState<any>([])
  const deviceId = getCookie('deviceId')
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId: uid, deviceId }))
  const [deviceName, setDeviceName] = useState(device?.client?.current?.name)
  const [displayName, setDisplayName] = useState(device?.client?.current?.displayName)

  const [files, setFiles] = useState<ImageUploadItem[]>([
    {
      url: device?.client?.current?.avatar || DEFAULT_AVATAR,
    },
  ])

  const upload = async (file: File) => {
    setDeviceAvatar(file)
    return {
      url: URL.createObjectURL(file),
    }
  }

  const handleUpdateDevice = async () => {
    const deviceAvatarId = uuid()

    const uploadStorage = async () => {
      if (deviceAvatar.length === 0) {
        return
      }
      const storageRef = ref(getStorage(), `images/device-avatars/${deviceAvatarId}`)
      const snapshot = await uploadBytes(storageRef, deviceAvatar)
      return snapshot.metadata.fullPath
    }

    await Promise.all([uploadStorage()]).then((path) => {
      const getStorageUrl = async () => {
        if (!path[0]) {
          return DEFAULT_AVATAR
        }
        const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
        const downloadURLs = await getDownloadURL(ref(getStorage(), url))
        return downloadURLs
      }
      Promise.all([getStorageUrl()]).then((downloadURLs) => {
        setDevice({
          ...device,
          client: {
            ...device.client,
            current: {
              ...device.client.current,
              avatar: downloadURLs[0],
              name: deviceName,
              displayName,
              updatedAt: Timestamp.now(),
            },
          },
        })
        navigate('/')
      })
    })
  }
  return (
    <CenterPopup visible={true} onMaskClick={() => navigate('/')}>
      <Grid columns={1} gap={10} style={{ padding: '20px 12px 6px', width: '100%', boxSizing: 'border-box' }}>
        <Grid.Item>
          <AutoCenter style={{ fontSize: '15px', fontWeight: '700' }}>{t('input-device.title')}</AutoCenter>
        </Grid.Item>
        <Grid.Item style={{ fontSize: '15px', fontWeight: '400' }}>
          <AutoCenter>{t('input-device.message1')}</AutoCenter>
          <AutoCenter>{t('input-device.message2')}</AutoCenter>
        </Grid.Item>
        <Grid.Item style={{ display: 'flex', flexDirection: 'column', padding: '0 12px' }}>
          <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666', marginBottom: '10px' }}>
            {t('input-device.avatar')}
          </Space>
          <ImageUploader value={files} onChange={setFiles} upload={upload} maxCount={1} />
        </Grid.Item>
        <Form
          style={{ '--border-top': 'none' }}
          footer={
            <Button
              color="primary"
              loading="auto"
              style={{ width: '100%', fontSize: '18px', fontWeight: '400' }}
              disabled={deviceName.length === 0 || displayName.length === 0}
              onClick={handleUpdateDevice}
            >
              {t('input-device.button.register')}
            </Button>
          }
        >
          <Form.Item label={t('input-device.name')}>
            <Input
              placeholder="スグニーのipad"
              value={deviceName}
              onChange={(value) => setDeviceName(value)}
              autoComplete="on"
            />
          </Form.Item>
          <Form.Item label={t('input-device.display-name')}>
            <Input
              placeholder="スグニー 太郎"
              value={displayName}
              onChange={(value) => setDisplayName(value)}
              autoComplete="on"
            />
          </Form.Item>
        </Form>
      </Grid>
    </CenterPopup>
  )
}

export default InputDevice
