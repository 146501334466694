import React, { useState } from 'react'
import { Button, Grid, Space, AutoCenter, Input, Toast, Form, Dialog } from 'antd-mobile'
import { Link } from 'react-router-dom'
import LoginHeader from '../../components/LoginHeader'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { setCookie } from 'react-use-cookie'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils/Utils'
import Loading from '../loading/Loading'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { LANGUAGE, languageCurrentRecoil } from '../../recoils/language'
import { Select } from 'antd'

const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

const Register: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [accountExist, setAccountExist] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [openActivateAccount, setOpenActivateAccount] = useState(false)
  const [isActivatingAccount, setIsActivatingAccount] = useState(false)
  const [languageCurrent, setLanguageCurrent] = useRecoilState(languageCurrentRecoil)

  const handleRegister = async () => {
    const checkDisabledAccount = httpsCallable(functions, 'CheckDisabledAccount')
    checkDisabledAccount({ email })
      .then((res) => {
        if ((res.data as { message: string }).message === 'ACCOUNT_DISABLED') {
          setOpenActivateAccount(true)
        } else {
          if ((res.data as { message: string }).message === 'ACCOUNT_ACTIVATED') {
            setAccountExist(true)
            Toast.show({
              content: t('register.message.account-exist'),
              duration: 3000,
            })
          }
        }
      })
      .catch(() => {
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (newUser) => {
            setAccountExist(false)
            const url = `${window?.location.origin}/login`

            const actionCodeSettings = {
              url: url,
              handleCodeInApp: true,
            }
            auth.languageCode = languageCurrent
            sendEmailVerification(newUser.user, actionCodeSettings).then(() => {
              navigate('/register/confirm')
              setCookie('email', email, { path: '/' })
            })
          })
          .catch((error) => {
            setAccountExist(true)
            Toast.show({
              content: t('register.message.account-exist'),
              duration: 3000,
            })
            throw new Error(error.message)
          })
      })
  }

  const handleActivateDevice = () => {
    setIsActivatingAccount(true)
    const reactivateAccount = httpsCallable(functions, 'ReactivateAccount')
    reactivateAccount({ email })
      .then(() => {
        Toast.show({
          content: t('register.message.account-activated'),
          position: 'top',
          duration: 3000,
        })
        setAccountExist(true)
        navigate('/login')
      })
      .catch(() => {
        Toast.show({
          content: t('register,message.account-activation-failed'),
          position: 'top',
          duration: 3000,
        })
      })
      .finally(() => {
        setIsActivatingAccount(false)
      })
  }

  return (
    <div
      className="container"
      style={{ backgroundColor: 'var(--main-background-color)', padding: '60px 20px', position: 'relative' }}
    >
      <Select
        labelInValue
        style={{ position: 'absolute', top: '25px', right: '30px' }}
        defaultValue={{
          value: languageCurrent,
          label: languageCurrent === LANGUAGE.JAPANESE.VALUE ? LANGUAGE.JAPANESE.NAME : LANGUAGE.ENGLISH.NAME,
        }}
        onChange={({ value }) => setLanguageCurrent(value)}
        options={[
          {
            value: LANGUAGE.JAPANESE.VALUE,
            label: LANGUAGE.JAPANESE.NAME,
          },
          {
            value: LANGUAGE.ENGLISH.VALUE,
            label: LANGUAGE.ENGLISH.NAME,
          },
        ]}
      />
      {isActivatingAccount ? (
        <Loading />
      ) : (
        <Grid columns={1} gap={15}>
          <LoginHeader />
          <Form
            style={{
              borderRadius: 'var(--wrapper-border-radius)',
              padding: ' 10px 10px 15px',
              backgroundColor: '#ffffff',
              '--border-top': 'none',
              '--border-bottom': 'none',
              marginTop: '16px',
            }}
          >
            <Form.Header>
              <AutoCenter style={{ fontSize: '20px', fontWeight: 400, color: '#000000' }}>
                {t('register.title')}
              </AutoCenter>
            </Form.Header>
            <Form.Item label={t('register.email')}>
              <Input onChange={(value) => setEmail(value)} type="email" placeholder="example@sugnee.jp" />
              {accountExist && (
                <Space style={{ fontSize: '13px', color: '#FF3141' }}>{t('register.message.account-exist')}</Space>
              )}
            </Form.Item>
            <Form.Item label={t('register.password')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  onChange={(value) => setPassword(value)}
                  placeholder="********"
                  type={visiblePassword ? 'text' : 'password'}
                  autoComplete="off"
                />
                <div style={{ cursor: 'pointer' }}>
                  {visiblePassword ? (
                    <EyeOutline onClick={() => setVisiblePassword(false)} />
                  ) : (
                    <EyeInvisibleOutline onClick={() => setVisiblePassword(true)} />
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                color="primary"
                loading="auto"
                style={{ width: '100%', height: '40px', borderRadius: 'var(--adm-button-border-radius)' }}
                disabled={!(isValidEmail(email) && password.length >= 8)}
                onClick={handleRegister}
              >
                {t('register.button.sign-up')}
              </Button>
            </Form.Item>
            <Link to="/login">
              <Space block justify="end" style={{ fontWeight: 400, fontSize: '16px' }}>
                <Button color="primary" fill="none" style={{ textDecoration: 'underline' }}>
                  {t('register.button.login')}
                </Button>
              </Space>
            </Link>
          </Form>
        </Grid>
      )}

      <Dialog
        visible={openActivateAccount}
        header={<div style={{ fontSize: '16px', color: '#000000' }}>{t('register.dialog.header')}</div>}
        content={
          <div style={{ maxWidth: '250px', margin: 'auto', padding: '10px 0' }}>{t('register.dialog.content')}</div>
        }
        closeOnAction
        bodyStyle={{ maxWidth: '400px' }}
        onClose={() => setOpenActivateAccount(false)}
        actions={[
          [
            {
              key: 'cancel',
              text: t('register.dialog.button.cancel'),
              style: { color: '#666666' },
            },
            {
              key: 'restart',
              text: t('register.dialog.button.restart'),
              style: { color: '#000000', fontWeight: 'bold' },
              onClick: handleActivateDevice,
            },
          ],
        ]}
      />
    </div>
  )
}

export default Register
