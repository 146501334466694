import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, FC, ReactNode, Suspense, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useCookie, { setCookie } from 'react-use-cookie'
import HomeLoading from '../pages/loading/HomeLoading'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { managementDevices } from '../recoils/devices'
import HomeTemplate from '../components/home/HomeTemplate'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { languageCurrentRecoil } from '../recoils/language'

type AuthProviderProps = {
  isHome?: boolean
  children?: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children, isHome = false }) => {
  const navigate = useNavigate()
  const auth = getAuth()
  const [uid, setUid] = useCookie('uid')
  const [organization, setOrganization] = useCookie('organization')
  const [deviceId, setDeviceId] = useCookie('deviceId')
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  const { i18n } = useTranslation()
  const [loadingI18n, setLoadingI18n] = useState(true)

  const languageCurrent = useRecoilValue(languageCurrentRecoil)

  useEffect(() => {
    i18n.changeLanguage(languageCurrent).then(() => setLoadingI18n(false))
  }, [i18n, languageCurrent])

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (uid && user && user?.uid && uid !== user?.uid) {
        window.location.reload()
      }
      setCurrentUser(user)
      setUid(user?.uid as string)
      if (!user || !uid) {
        setCookie('deviceId', '', { path: '/' })
        setCookie('email', '', { path: '/' })
        setCookie('organization', '', { path: '/' })
        navigate('/login')
        return
      }

      if (!user.displayName || !user.photoURL) {
        navigate('/register/user')
        return
      }

      Promise.all([managementDevices()]).then((result) => {
        const devices = result[0]
        if (!devices.length) {
          setOrganization('')
          setDeviceId('')
          return
        }
        if (!organization || devices.every((device) => device.organization !== organization))
          setOrganization(devices[0].organization)

        if (!deviceId || devices.every((device) => device.data.id !== deviceId)) setDeviceId(devices[0].data.id)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentUser || !uid || loadingI18n) {
    return <HomeLoading />
  }

  return (
    <Suspense fallback={<HomeLoading />}>
      <HelmetProvider>
        <Helmet>
          <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
        </Helmet>
        {isHome ? (
          <HomeTemplate
            organization={organization}
            setOrganization={setOrganization}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
          >
            {children}
          </HomeTemplate>
        ) : (
          <>{children}</>
        )}
      </HelmetProvider>
    </Suspense>
  )
}
