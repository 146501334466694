import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Popup, Toast } from 'antd-mobile'
import { getCookie } from 'react-use-cookie'
import { useRecoilValue } from 'recoil'
import { managersRecoil } from '../../recoils/managers'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../utils/Utils'
import { deviceRecoil } from '../../recoils/device'
import { useTranslation } from 'react-i18next'
import { languageCurrentRecoil } from '../../recoils/language'

const AddManager: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const navigate = useNavigate()
  const uid = getCookie('uid')
  const deviceId = getCookie('deviceId')
  const [email, setEmail] = useState('')
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [visible, setVisible] = useState(false)
  const managers = useRecoilValue(managersRecoil(uid))
  const languageCurrent = useRecoilValue(languageCurrentRecoil)
  const deviceDetail = useRecoilValue(
    deviceRecoil({ organizationId: auth.currentUser?.uid as string, deviceId: deviceId })
  )

  useEffect(() => {
    setVisible(true)
  }, [])

  const handleAddManager = () => {
    setIsSendingEmail(true)
    const sendInvitationEmail = httpsCallable(functions, 'SendInvitationEmail')
    const url = `${window?.location.origin}/accept-invitation/?email=${email}&inviterId=${uid}&deviceId=${deviceId}`

    sendInvitationEmail({
      continueURL: url,
      email,
      deviceName: deviceDetail.client.current.displayName,
      template: languageCurrent,
    })
      .then(() => {
        setIsSendingEmail(false)
        setVisible(true)
        Toast.show({
          content: t('add-manager.message.send-email'),
          position: 'top',
          duration: 3000,
        })
      })
      .catch(() => {
        setIsSendingEmail(false)
        setVisible(true)
        Toast.show({
          content: t('add-manager.message.send-email.error'),
          position: 'top',
          duration: 3000,
        })
      })
  }

  return (
    <Popup
      visible={visible}
      onMaskClick={() => navigate('/device/edit/managers')}
      position="bottom"
      bodyStyle={{ height: '35vh', minHeight: '240px' }}
    >
      <Form
        layout="vertical"
        style={{ padding: '20px', '--border-top': 'none' }}
        footer={
          <Button
            block
            color="primary"
            disabled={managers.filter((manager) => manager.email === email).length !== 1}
            onClick={handleAddManager}
            loading={isSendingEmail}
          >
            {t('add-manager.button.add')}
          </Button>
        }
      >
        <Form.Item name="name" label={t('add-manager.email')}>
          <Input type="email" value={email} onChange={(value) => setEmail(value)} placeholder="example@sugnee.jp" />
        </Form.Item>
        {email && managers.filter((manager) => manager.email === email).length !== 1 && (
          <Form.Item style={{ color: 'var(--adm-color-danger)' }}>{t('add-manager.message.not-found')}</Form.Item>
        )}
      </Form>
    </Popup>
  )
}

export default AddManager
