import React, { useState } from 'react'
import { Button, ImageUploader, ImageUploadItem, NavBar, Space } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage'
import { deviceRecoil } from '../../../recoils/device'
import { getCookie } from 'react-use-cookie'
import { v4 as uuid } from 'uuid'
import { useTranslation } from 'react-i18next'

const EditDeviceAvatar: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId: organization, deviceId }))
  const [deviceAvatar, setDeviceAvatar] = useState<any>([])
  const [files, setFiles] = useState<ImageUploadItem[]>([{ url: device?.client?.current?.avatar }])
  const [edited, setEdited] = useState(false)

  const upload = async (file: File) => {
    setDeviceAvatar(file)
    return {
      url: URL.createObjectURL(file),
    }
  }

  const handleEditDeviceAvatar = async () => {
    setEdited(true)
    const deviceAvatarId = uuid()

    const uploadStorage = async () => {
      const storageRef = ref(getStorage(), `images/device-avatars/${deviceAvatarId}`)
      const snapshot = await uploadBytes(storageRef, deviceAvatar)
      return snapshot.metadata.fullPath
    }

    await Promise.all([uploadStorage()]).then((path) => {
      const getStorageUrl = async () => {
        const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
        const downloadURLs = await getDownloadURL(ref(getStorage(), url))
        return downloadURLs
      }

      Promise.all([getStorageUrl()]).then((downloadURLs) => {
        setDevice({
          ...device,
          client: { ...device.client, current: { ...device.client.current, avatar: downloadURLs[0] } },
        })
        navigate('/device/detail')
      })
    })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/device/detail')}
      >
        {t('device.edit.avatar.title')}
      </NavBar>

      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 20px' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>{t('device.edit.avatar.title')}</Space>
        <ImageUploader
          value={files}
          onChange={setFiles}
          upload={upload}
          style={{ margin: '8px 0 25px' }}
          maxCount={1}
        />
        <Button
          color="primary"
          fill="solid"
          style={{ width: '100%' }}
          loading="auto"
          onClick={handleEditDeviceAvatar}
          disabled={edited || files.length < 1}
        >
          {t('device.edit.avatar.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditDeviceAvatar
