import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

// import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
// import { connectAuthEmulator, getAuth } from 'firebase/auth'
// import { getStorage, connectStorageEmulator } from 'firebase/storage'
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

if (!getApps().length) {
  const app = initializeApp(firebaseConfig)

  getAnalytics(app)
  // Connect to the emulators if in development mode
  // if (process.env.NODE_ENV !== 'production') {
  //   connectStorageEmulator(getStorage(), 'localhost', 9199)
  //   connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
  //   connectAuthEmulator(getAuth(), 'http://localhost:9099')
  // }
}
