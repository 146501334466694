import React, { useState } from 'react'
import { Button, Form, NavBar, Radio, Space } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { LANGUAGE, languageCurrentRecoil } from '../../../recoils/language'
import { useTranslation } from 'react-i18next'

const EditLanguage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [languageCurrent, setLanguageCurrent] = useRecoilState(languageCurrentRecoil)
  const [language, setLanguage] = useState(languageCurrent)

  const handleEditLanguage = () => {
    setLanguageCurrent(language)
    navigate('/user')
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/user')}
      >
        {t('user.edit-language.title')}
      </NavBar>
      <Form
        style={{
          padding: '60px 25px 0',
          backgroundColor: '#ffffff',
          '--border-top': 'none',
          '--border-bottom': 'none',
        }}
      >
        <Form.Item label={t('user.edit-language.title')}>
          <Radio.Group onChange={(value) => setLanguage(value)} value={language}>
            <Space direction="vertical" style={{ padding: '10px 20px' }}>
              <Radio value={LANGUAGE.ENGLISH.VALUE}>{LANGUAGE.ENGLISH.NAME}</Radio>
              <Radio value={LANGUAGE.JAPANESE.VALUE}>{LANGUAGE.JAPANESE.NAME}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            color="primary"
            fill="solid"
            style={{ width: '100%' }}
            disabled={language === languageCurrent}
            onClick={handleEditLanguage}
          >
            {t('user.edit-language.button.save')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default EditLanguage
