import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import './i18n/init'
import './firebase-config'
import { RecoilRoot } from 'recoil'
import Login from './pages/login/Login'
import ConfirmLogin from './pages/login/ConfirmLogin'
import LoginWithPassword from './pages/login/LoginWithPassword'
import Register from './pages/register/Register'
import ConfirmRegister from './pages/register/ConfirmRegister'
import InputPassCode from './components/home/InputPassCode'
import InputDevice from './components/home/InputDevice'
import RegisterUser from './pages/register/RegisterUser'
import CallPage from './pages/call'
import DeviceDetail from './pages/device/Detail'
import EditAvatar from './pages/device/edit/Avatar'
import EditDeviceName from './pages/device/edit/Name'
import EditDeviceDisplayName from './pages/device/edit/DisplayName'
import User from './pages/user'
import EditUserAvatar from './pages/user/edit/Avatar'
import EditUserDisplayName from './pages/user/edit/DisplayName'
import EditPassword from './pages/user/edit/Password'
import EditDeviceCallButton from './pages/device/edit/CallButton'
import EditDeviceContacts from './pages/device/edit/Contacts'
import { LoginCallback } from './components/LoginCallback'
import { AuthProvider } from './auth/AuthProvider'
import { NoAuthProvider } from './auth/NoAuthProvider'
import Managers from './pages/device/edit/Managers'
import AddManager from './components/device/AddManager'
import { AcceptInvitation } from './components/AcceptInvitation'
import CreatePost from './components/posts/Create'
import { PostTypes } from './constants'
import HomeLoading from './pages/loading/HomeLoading'
import EditPost from './components/posts/Edit'
import { ChangeEmailCallback } from './components/ChangeEmailCallback'
import EditEmail from './pages/user/edit/Email'
import EditDeviceLikeButton from './pages/device/edit/LikeButton'
import EditLanguage from './pages/user/edit/Language'

const SugneeManagement: React.FC = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <NoAuthProvider>
                <Login />
              </NoAuthProvider>
            }
          />
          <Route
            path="/register"
            element={
              <NoAuthProvider>
                <Register />
              </NoAuthProvider>
            }
          />
          <Route
            path="/login/confirm"
            element={
              <NoAuthProvider>
                <ConfirmLogin />
              </NoAuthProvider>
            }
          />
          <Route
            path="/login/callback"
            element={
              <NoAuthProvider>
                <LoginCallback />
              </NoAuthProvider>
            }
          />
          <Route
            path="/accept-invitation"
            element={
              <NoAuthProvider>
                <AcceptInvitation />
              </NoAuthProvider>
            }
          />
          <Route
            path="/register/confirm"
            element={
              <NoAuthProvider>
                <ConfirmRegister />
              </NoAuthProvider>
            }
          />
          <Route
            path="/change-email/callback"
            element={
              <NoAuthProvider>
                <ChangeEmailCallback />
              </NoAuthProvider>
            }
          />
          <Route
            path="/login/password"
            element={
              <NoAuthProvider>
                <LoginWithPassword />
              </NoAuthProvider>
            }
          />

          <Route
            path="/"
            element={
              <Suspense fallback={<HomeLoading />}>
                <AuthProvider isHome={true} />
              </Suspense>
            }
          >
            <Route path="post/message" element={<CreatePost postType={PostTypes.TEXT} />} />
            <Route path="post/image" element={<CreatePost postType={PostTypes.IMAGE} />} />
            <Route path="post/edit/:id" element={<EditPost />} />
          </Route>
          <Route
            path="input-device"
            element={
              <AuthProvider isHome={true}>
                <InputDevice />
              </AuthProvider>
            }
          />
          <Route
            path="input-passcode"
            element={
              <AuthProvider isHome={true}>
                <InputPassCode />
              </AuthProvider>
            }
          />
          <Route
            path="/call"
            element={
              <AuthProvider>
                <CallPage />
              </AuthProvider>
            }
          />
          <Route
            path="/register/user"
            element={
              <AuthProvider>
                <RegisterUser />
              </AuthProvider>
            }
          />
          <Route
            path="/device/detail"
            element={
              <AuthProvider>
                <DeviceDetail />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/avatar"
            element={
              <AuthProvider>
                <EditAvatar />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/name"
            element={
              <AuthProvider>
                <EditDeviceName />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/display-name"
            element={
              <AuthProvider>
                <EditDeviceDisplayName />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/call-button"
            element={
              <AuthProvider>
                <EditDeviceCallButton />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/like-button"
            element={
              <AuthProvider>
                <EditDeviceLikeButton />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/contacts"
            element={
              <AuthProvider>
                <EditDeviceContacts />
              </AuthProvider>
            }
          />
          <Route
            path="/device/edit/managers"
            element={
              <AuthProvider>
                <Managers />
              </AuthProvider>
            }
          >
            <Route path="add" element={<AddManager />} />
          </Route>
          <Route
            path="/user"
            element={
              <AuthProvider>
                <User />
              </AuthProvider>
            }
          />
          <Route
            path="/user/edit/avatar"
            element={
              <AuthProvider>
                <EditUserAvatar />
              </AuthProvider>
            }
          />
          <Route
            path="/user/edit/display-name"
            element={
              <AuthProvider>
                <EditUserDisplayName />
              </AuthProvider>
            }
          />
          <Route
            path="/user/edit/password"
            element={
              <AuthProvider>
                <EditPassword />
              </AuthProvider>
            }
          />
          <Route
            path="/user/edit/language"
            element={
              <AuthProvider>
                <EditLanguage />
              </AuthProvider>
            }
          />
          <Route
            path="/user/edit/email"
            element={
              <AuthProvider>
                <EditEmail />
              </AuthProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  )
}

export default SugneeManagement
