import React, { useState } from 'react'
import { AutoCenter, Button, DotLoading, Grid, InfiniteScroll, Space } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import Post from './Detail'
import { DeviceManagement } from '../../recoils/devices'
import { useRecoilValue } from 'recoil'
import { postsByLimit, postsNextBatch } from '../../recoils/posts'
import { useTranslation } from 'react-i18next'

type PostProps = {
  devices: DeviceManagement[]
  isExpand: boolean
  organizationId: string
  deviceId: string
}

const InfiniteScrollContent: React.FC<{ hasMore?: boolean }> = ({ hasMore }) => {
  const { t } = useTranslation()
  if (!hasMore) {
    return <span>{t('posts.infinite-scroll.first-post')}</span>
  }

  return (
    <>
      <span>{t('posts.infinite-scroll.loading')}</span>
      <DotLoading />
    </>
  )
}

const Posts: React.FC<PostProps> = ({ isExpand, organizationId, deviceId, devices }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [hasMore, setHasMore] = useState(true)
  const [limitNumber, setLimitNumber] = useState(5)
  const posts = useRecoilValue(postsByLimit({ organizationId, deviceId, limitNumber }))
  const [lastKey, setLastKey] = useState(posts[posts?.length - 1]?.createdAt || '')

  const loadMore = async () => {
    const newPosts = await postsNextBatch({ lastKey, organizationId, deviceId })
    setLimitNumber((oldLimit) => oldLimit + 10)
    setHasMore(newPosts?.length > 0)
    setLastKey(newPosts[newPosts?.length - 1]?.createdAt)
  }

  if (!devices || devices.length === 0) {
    return (
      <Grid
        columns={1}
        gap={24}
        style={{
          boxSizing: 'border-box',
          padding: isExpand ? '137px 12px 16px' : '79px 12px 16px',
          transition: 'all .3s',
        }}
      >
        <div className="card-empty">
          <Space style={{ display: 'block' }}>{t('posts.no-device.content1')}</Space>
          <Space style={{ display: 'block' }}>{t('posts.no-device.content2')}</Space>
          <AutoCenter style={{ marginTop: '20px' }}>
            <Button color="primary" fill="solid" shape="rounded" onClick={() => navigate('/input-passcode')}>
              {t('posts.no-device.button-register')}
            </Button>
          </AutoCenter>
        </div>
      </Grid>
    )
  }

  if (!posts || posts.length === 0) {
    return (
      <Grid
        columns={1}
        gap={24}
        style={{
          boxSizing: 'border-box',
          padding: isExpand ? '137px 12px 16px' : '79px 12px 16px',
          transition: 'all .3s',
        }}
      >
        <div className="card-empty" style={{ boxShadow: 'var(--box-shadow)' }}>
          <Space style={{ display: 'block' }}>{t('posts.no-post.content1')}</Space>
          <Space style={{ display: 'block' }}>{t('posts.no-post.content2')}</Space>
          <AutoCenter style={{ marginTop: '20px' }}>
            <Button color="primary" fill="solid" shape="rounded" onClick={() => navigate('/post/message')}>
              {t('posts.no-post.button-create')}
            </Button>
          </AutoCenter>
        </div>
      </Grid>
    )
  }

  return (
    <>
      <Grid
        columns={1}
        gap={24}
        style={{
          boxSizing: 'border-box',
          padding: isExpand ? '137px 12px 16px' : '79px 12px 16px',
          transition: 'all .3s',
        }}
      >
        {posts.map((post, index) => (
          <Grid.Item key={index}>
            <Post post={post} />
          </Grid.Item>
        ))}
      </Grid>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </>
  )
}

export default Posts
