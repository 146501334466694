import { atomFamily } from 'recoil'
import Organization from '../entities/Organization'
import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'

export const organizationRecoil = atomFamily<Organization, string>({
  key: 'organization',
  default: async (organizationId) => {
    const organizationRef = doc(getFirestore(), 'organizations', organizationId)
    const organizationSnapshot = await getDoc(organizationRef)

    return organizationSnapshot.data() as Organization
  },
  effects: (organizationId) => {
    return [
      ({ setSelf }) => {
        const organizationRef = doc(getFirestore(), 'organizations', organizationId)

        return onSnapshot(organizationRef, (snapshot) => {
          setSelf(snapshot.data() as Organization)
        })
      },
      ({ onSet }) => {
        const organizationRef = doc(getFirestore(), `/organizations/${organizationId}`)
        return onSet((newValue) => {
          if (newValue) {
            return updateDoc(organizationRef, newValue)
          }
        })
      },
    ]
  },
})
