import React, { useState } from 'react'
import { Button, AutoCenter, Input, NavBar, Toast, Form } from 'antd-mobile'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import { getCookie, setCookie } from 'react-use-cookie'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { useTranslation } from 'react-i18next'

const LoginWithPassword: React.FC = () => {
  const { t } = useTranslation()
  const auth = getAuth()
  const email = getCookie('email')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const [visiblePassword, setVisiblePassword] = useState(false)

  const handleLogin = async () => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        setCookie('uid', user.uid, { path: '/' })
        navigate('/')
      })
      .catch(() => {
        Toast.show({
          content: t('login-password.message.error'),
          duration: 3000,
        })
      })
  }

  return (
    <div className="container" style={{ backgroundColor: 'var(--main-background-color)', padding: '10px 20px 60px' }}>
      <Link to="/login/confirm">
        <NavBar style={{ color: '#ffffff' }} />
      </Link>
      <Form
        style={{
          padding: '10px 10px 15px',
          borderRadius: 'var(--wrapper-border-radius)',
          boxShadow: 'var(--box-shadow)',
          backgroundColor: '#ffffff',
          '--border-top': 'none',
          '--border-bottom': 'none',
        }}
      >
        <Form.Header>
          <AutoCenter style={{ fontSize: '20px', fontWeight: 400, color: '#000000' }}>
            {t('login-password.header')}
          </AutoCenter>
        </Form.Header>
        <Form.Item label={t('login-password.email')}>
          <Input value={email} type="email" placeholder="example@sugnee.jp" autoComplete="on" disabled />
        </Form.Item>
        <Form.Item name="password" label={t('login-password.password')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              type={visiblePassword ? 'text' : 'password'}
              onChange={(value) => setPassword(value)}
              placeholder="********"
              autoComplete="off"
            />
            <div style={{ cursor: 'pointer' }}>
              {visiblePassword ? (
                <EyeOutline onClick={() => setVisiblePassword(false)} />
              ) : (
                <EyeInvisibleOutline onClick={() => setVisiblePassword(true)} />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            color="primary"
            loading="auto"
            style={{ width: '100%', height: '40px', borderRadius: 'var(--wrapper-border-radius)' }}
            disabled={password.length < 8}
            onClick={handleLogin}
          >
            {t('login-password.button.login')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginWithPassword
