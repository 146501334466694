import React, { Suspense, useEffect, useState } from 'react'
import { getAuth, User } from 'firebase/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { Timestamp } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { PostTypes } from '../../constants'
import DeviceInformation from './DeviceInformation'
import { Button, Grid, ImageUploader, ImageUploadItem, NavBar, Popup, Space, Tabs, TextArea, Toast } from 'antd-mobile'
import { getCookie } from 'react-use-cookie'
import { useRecoilState } from 'recoil'
import { showPostPopup } from '../../recoils/posts'
import { v4 as uuid } from 'uuid'
import { postRecoil } from '../../recoils/post'
import { useTranslation } from 'react-i18next'

const EditPost: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const organizationId = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const { id } = useParams()
  const [post, setPost] = useRecoilState(postRecoil({ organizationId, deviceId, postId: id as string }))
  const { uid, photoURL, displayName } = auth.currentUser as User
  const [files, setFiles] = useState<ImageUploadItem[]>(post?.type === PostTypes.IMAGE ? [{ url: post.content }] : [])
  const [postImage, setPostImage] = useState<any>(null)
  const [postMessage, setPostMessage] = useState(post?.type === PostTypes.TEXT ? post?.content : '')
  const [postType, setPostType] = useState(post?.type)
  const [postDescription, setPostDescription] = useState(post?.type === PostTypes.TEXT ? '' : post?.description || '')
  const [showPopup, setShowPopup] = useRecoilState(showPostPopup)
  const [posted, setPosted] = useState(false)

  useEffect(() => {
    setShowPopup(true)

    return () => {
      setShowPopup(false)
    }
  }, [setShowPopup])

  const handleEditPost = async () => {
    setPosted(true)
    const uploadStorage = async () => {
      if (postType === PostTypes.TEXT) {
        return
      }

      if (!postImage) {
        return
      }

      const postImageId = uuid()
      const storageRef = ref(getStorage(), `images/post/${postImageId}`)
      const snapshot = await uploadBytes(storageRef, postImage)
      return snapshot.metadata.fullPath
    }
    await Promise.all([uploadStorage()]).then((path) => {
      const getContent = async () => {
        if (postType === PostTypes.TEXT) {
          return postMessage
        }

        if (!postImage) {
          return post.content
        }

        const url = `${process.env.REACT_APP_BASE_URL_STORAGE}${path}`
        const downloadURL = await getDownloadURL(ref(getStorage(), url))
        return downloadURL
      }
      Promise.all([getContent()]).then((contents) => {
        Promise.all([
          setPost({
            ...post,
            content: contents[0],
            description: postType === PostTypes.IMAGE ? postDescription : '',
            type: postType,
            createdByUser: {
              id: uid,
              avatar: photoURL || '',
              displayName: displayName || '',
            },
            updatedAt: Timestamp.now(),
          }),
        ])
          .then(() => {
            Toast.show({
              content: t('posts.edit.message.completion'),
              duration: 3000,
              afterClose: () => navigate('/'),
            })
          })
          .catch((error) => {
            Toast.show({
              content: error.message,
              duration: 3000,
              afterClose: () => navigate('/'),
            })
            throw new Error(error.message)
          })
        window.scrollTo(0, 0)
      })
    })
  }

  const upload = async (file: File) => {
    setPostImage(file)
    return {
      url: URL.createObjectURL(file),
    }
  }
  return (
    <Popup
      visible={showPopup}
      onMaskClick={() => {
        setShowPopup(false)
        navigate('/')
      }}
      bodyStyle={{ height: '80vh' }}
    >
      <NavBar
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        left={
          <Button
            color="primary"
            fill="none"
            disabled={posted}
            style={{ borderRadius: '0', fontSize: '14px', padding: '3px 0 0 0' }}
            onClick={() => navigate('/')}
          >
            {t('posts.edit.button.cancel')}
          </Button>
        }
        right={
          <Button
            color="primary"
            fill="none"
            style={{ borderRadius: '0', fontSize: '14px', padding: '3px 12px 0' }}
            loading="auto"
            disabled={
              posted ||
              !deviceId ||
              (postType === PostTypes.TEXT
                ? postMessage.length === 0
                : !postImage && postDescription === post?.description)
            }
            onClick={handleEditPost}
          >
            {t('posts.edit.button.post')}
          </Button>
        }
        onBack={() => navigate('/')}
        backArrow={false}
      >
        <Space style={{ color: '#333333', fontSize: '15px' }}>{t('posts.edit.title')}</Space>
      </NavBar>
      <Tabs activeLineMode="full" defaultActiveKey={postType} onChange={(key) => setPostType(key as PostTypes)}>
        <Tabs.Tab title={t('posts.edit.tabs.message')} key={PostTypes.TEXT} style={{ width: '50%' }} disabled={posted}>
          <Grid columns={1} gap={36}>
            <Grid.Item>
              {deviceId && (
                <Suspense>
                  <DeviceInformation organizationId={organizationId} deviceId={deviceId} />
                </Suspense>
              )}
            </Grid.Item>
            <Grid.Item>
              <TextArea
                defaultValue={postMessage}
                showCount
                maxLength={15}
                placeholder={t('posts.edit.content.placeholder') || 'ここにメッセージを入力してください。'}
                onChange={(value) => setPostMessage(value)}
                disabled={posted}
              />
              {postMessage.length > 15 && (
                <Space style={{ fontSize: '13px', fontWeight: '400', color: '#FF3141' }}>
                  {t('posts.edit.message.content-max-length')}
                </Space>
              )}
            </Grid.Item>
          </Grid>
        </Tabs.Tab>
        <Tabs.Tab title={t('posts.edit.tabs.image')} key={PostTypes.IMAGE} style={{ width: '50%' }} disabled={posted}>
          <Grid columns={1} gap={36}>
            <Grid.Item>
              {deviceId && <DeviceInformation organizationId={organizationId} deviceId={deviceId} />}
            </Grid.Item>
            <Grid.Item>
              <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666', marginBottom: '8px' }}>
                {t('posts.edit.title.image')}
              </Space>
              <ImageUploader
                value={files}
                onChange={posted ? () => {} : setFiles}
                upload={upload}
                maxCount={1}
                disableUpload={posted}
              />
            </Grid.Item>
            <Grid.Item>
              <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666', marginBottom: '8px' }}>
                {t('posts.edit.message.description-title')}
              </Space>
              <TextArea
                defaultValue={postDescription}
                showCount
                maxLength={30}
                placeholder={t('posts.edit.description.placeholder') || 'ここにタイトルを入力してください。'}
                onChange={(value) => setPostDescription(value)}
                disabled={posted}
              />
              {postDescription.length > 30 && (
                <Space style={{ fontSize: '13px', fontWeight: '400', color: '#FF3141' }}>
                  {t('posts.edit.message.description-max-length')}
                </Space>
              )}
            </Grid.Item>
          </Grid>
        </Tabs.Tab>
      </Tabs>
    </Popup>
  )
}

export default EditPost
