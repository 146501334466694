import { getAuth, isSignInWithEmailLink } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { signInWithEmailLink } from '@firebase/auth'
import { Toast } from 'antd-mobile'
import { setCookie } from 'react-use-cookie'
import HomeLoading from '../pages/loading/HomeLoading'

export const LoginCallback: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()

  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current && isSignInWithEmailLink(auth, window.location.href)) {
      ref.current = true
      const url = new URL(window.location.href)
      const email = url.searchParams.get('email') as string
      signInWithEmailLink(auth, email, window.location.href)
        .then(async ({ user }) => {
          setCookie('uid', user.uid, { path: '/' })
          navigate('/')
        })
        .catch(async (error) => {
          Toast.show({
            content: error.message,
            position: 'top',
            duration: 3000,
          })
          navigate('/login')
          throw new Error(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <HomeLoading />
}
