import React, { useState } from 'react'
import { Button, List, NavBar, Switch } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { deviceRecoil } from '../../../recoils/device'
import { CALL_BUTTON_STATUS } from '../../../entities/Device'
import { getCookie } from 'react-use-cookie'
import { useTranslation } from 'react-i18next'

const EditDeviceCallButton: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organization = getCookie('organization')
  const deviceId = getCookie('deviceId')
  const [device, setDevice] = useRecoilState(deviceRecoil({ organizationId: organization, deviceId }))
  const [callButtonStatus, setCallButtonStatus] = useState(device.client.current.callButtonStatus)

  const handleEditDeviceCallButton = () => {
    setDevice({
      ...device,
      client: { ...device.client, current: { ...device.client.current, callButtonStatus } },
    })
    navigate('/device/detail')
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/device/detail')}
      >
        {t('device.edit.call-status.title')}
      </NavBar>
      <List style={{ '--border-top': 'none', padding: '75px 25px 20px' }}>
        <List.Item
          extra={
            <Switch
              checked={callButtonStatus === CALL_BUTTON_STATUS.SHOW}
              onChange={(value) => setCallButtonStatus(value ? CALL_BUTTON_STATUS.SHOW : CALL_BUTTON_STATUS.HIDE)}
            />
          }
        >
          {t('device.edit.call-status.title')}
        </List.Item>
      </List>
      <List style={{ '--border-top': 'none', '--border-bottom': 'none', padding: '20px' }}>
        <List.Item>
          <Button
            color="primary"
            fill="solid"
            style={{ width: '100%', marginTop: '58px' }}
            onClick={handleEditDeviceCallButton}
          >
            {t('device.edit.call-status.button.save')}
          </Button>
        </List.Item>
      </List>
    </div>
  )
}

export default EditDeviceCallButton
