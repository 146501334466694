import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const LANGUAGE = {
  ENGLISH: {
    VALUE: 'en',
    NAME: 'English',
  },
  JAPANESE: {
    VALUE: 'ja',
    NAME: '日本語',
  },
}

export const languageCurrentRecoil = atom({
  key: 'language',
  default: LANGUAGE.JAPANESE.VALUE,
  effects_UNSTABLE: [persistAtom],
})
