import React, { useState } from 'react'
import { getAuth, updatePassword, User } from '@firebase/auth'
import { Button, Form, Input, NavBar, Toast } from 'antd-mobile'
import { CloseOutline, EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const EditPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const user = auth.currentUser as User
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false)

  const handleEditPassword = async () => {
    await updatePassword(user, password)
      .then(() => navigate('/user'))
      .catch((error) => {
        Toast.show({
          content: error.message,
          duration: 3000,
        })
        throw new Error(error.message)
      })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/user')}
      >
        {t('user.edit-password.title')}
      </NavBar>
      <Form
        style={{
          borderRadius: '24px',
          padding: ' 60px 25px 0',
          backgroundColor: '#ffffff',
          '--border-top': 'none',
          '--border-bottom': 'none',
        }}
      >
        <Form.Item name="password" label={t('user.edit-password.password')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={password}
              onChange={(value) => setPassword(value)}
              type={visiblePassword ? 'text' : 'password'}
              placeholder="********"
              autoComplete="off"
            />
            <div style={{ cursor: 'pointer' }}>
              {visiblePassword ? (
                <EyeOutline onClick={() => setVisiblePassword(false)} />
              ) : (
                <EyeInvisibleOutline onClick={() => setVisiblePassword(true)} />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="confirmPassword" label={t('user.edit-password.confirm-password')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={confirmPassword}
              onChange={(value) => setConfirmPassword(value)}
              type={visibleConfirmPassword ? 'text' : 'password'}
              placeholder="********"
              autoComplete="off"
            />
            <div style={{ cursor: 'pointer' }}>
              {visibleConfirmPassword ? (
                <EyeOutline onClick={() => setVisibleConfirmPassword(false)} />
              ) : (
                <EyeInvisibleOutline onClick={() => setVisibleConfirmPassword(true)} />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            color="primary"
            fill="solid"
            loading="auto"
            style={{ width: '100%', marginTop: '32px' }}
            disabled={password.length < 8 || confirmPassword.length < 8 || password !== confirmPassword}
            onClick={handleEditPassword}
          >
            {t('user.edit-password.button.save')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default EditPassword
