import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'
import { atomFamily } from 'recoil'
import Profile from '../entities/Profile'

export const profileRecoil = atomFamily<Profile, string>({
  key: 'profile',
  default: async (organizationId) => {
    const db = getFirestore()
    const profileRef = doc(db, `/organizations/${organizationId}`)
    const snapshot = await getDoc(profileRef)

    return snapshot.data() as Profile
  },
  effects: (organizationId) => {
    const db = getFirestore()
    return [
      ({ setSelf }) => {
        const profileRef = doc(db, `/organizations/${organizationId}`)
        return onSnapshot(profileRef, (snapshot) => {
          setSelf(snapshot.data() as Profile)
        })
      },
      ({ onSet }) => {
        const profileRef = doc(db, `/organizations/${organizationId}`)
        return onSet((newValue) => {
          if (newValue) {
            return updateDoc(profileRef, newValue)
          }
        })
      },
    ]
  },
})
