import { FC, ReactNode, useEffect, useState } from 'react'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { languageCurrentRecoil } from '../recoils/language'
import Loading from '../pages/loading/Loading'

export const NoAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation()
  const [loadingI18n, setLoadingI18n] = useState(true)

  const languageCurrent = useRecoilValue(languageCurrentRecoil)

  useEffect(() => {
    i18n.changeLanguage(languageCurrent).then(() => setLoadingI18n(false))
  }, [i18n, languageCurrent])

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="theme-color" content="#efe9d6" media="(prefers-color-scheme: light)" />
      </Helmet>
      {loadingI18n ? <Loading /> : children}
    </HelmetProvider>
  )
}
