import React from 'react'
import { Grid, Space, AutoCenter, NavBar, Button, Toast } from 'antd-mobile'
import { UndoOutline } from 'antd-mobile-icons'
import { Link, useNavigate } from 'react-router-dom'
import { getCookie } from 'react-use-cookie'
import { getAuth, sendSignInLinkToEmail } from '@firebase/auth'
import { useTranslation } from 'react-i18next'

const ConfirmLogin: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const email = getCookie('email')

  const handleResend = async () => {
    const url = `${window?.location.origin}/?email=${email}`

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    }

    await sendSignInLinkToEmail(auth, email, actionCodeSettings).catch((error) => {
      Toast.show({
        content: error.message,
        position: 'top',
        duration: 3000,
      })
      throw new Error(error.message)
    })
  }

  return (
    <div className="container" style={{ backgroundColor: 'var(--main-background-color)', padding: '10px 20px 60px' }}>
      <Link to="/login">
        <NavBar style={{ color: '#ffffff' }} />
      </Link>
      <Grid
        columns={1}
        gap="24px"
        style={{
          borderRadius: 'var(--wrapper-border-radius)',
          boxShadow: 'var(--box-shadow)',
          backgroundColor: '#ffffff',
          paddingTop: '32px',
          paddingRight: '15px',
          paddingBottom: '32px',
          paddingLeft: '15px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Grid.Item>
          <AutoCenter style={{ fontSize: '18px', fontWeight: 700, color: '#000000' }}>
            {t('confirm-login.header')}
          </AutoCenter>
        </Grid.Item>
        <Grid.Item>
          <Space block justify="center">
            <div
              style={{
                width: '133px',
                height: '157px',
                backgroundImage: `url('/images/sugnee.svg')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 'var(--avatar-border-radius)',
              }}
            />
          </Space>
        </Grid.Item>
        <Grid.Item style={{ color: '#000000', fontSize: '16px', fontWeight: '400' }}>
          <Space>{t('confirm-login.title')}</Space>
          <Space wrap style={{ display: 'block' }}>
            {t('confirm-login.content')}
          </Space>
        </Grid.Item>
        <Grid.Item>
          <AutoCenter>
            <Button color="primary" loading="auto" fill="none" style={{ fontSize: '12px' }} onClick={handleResend}>
              <UndoOutline style={{ marginRight: '5px' }} />
              {t('confirm-login.button.resend')}
            </Button>
          </AutoCenter>
        </Grid.Item>
        <Grid.Item style={{ color: '#000000', fontSize: '16px', fontWeight: '400' }}>
          <AutoCenter>
            <Button color="default" style={{ fontWeight: '400' }} onClick={() => navigate('/login/password')}>
              {t('confirm-login.button.login-password')}
            </Button>
          </AutoCenter>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default ConfirmLogin
