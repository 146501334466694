import { Timestamp } from 'firebase/firestore'

export enum CALL_BUTTON_STATUS {
  SHOW = 'show',
  HIDE = 'hide',
}

export enum LIKE_BUTTON_STATUS {
  SHOW = 'show',
  HIDE = 'hide',
}

type Contacts = {
  id: string
  displayName: string
  avatar: string
}

type Client = {
  current: {
    contacts: Contacts[]
    createdAt: Timestamp
    updatedAt: Timestamp
    avatar: string
    displayName: string
    name: string
    pushKitToken: string
    callButtonStatus: CALL_BUTTON_STATUS
    likeButtonStatus: LIKE_BUTTON_STATUS
    postDescriptionTimeout: number
  }
}

type Device = {
  id: string
  client: Client
  managersId: string[]
  identity: string
  notificationToken: string
}

export default Device
