import React, { useState } from 'react'
import { getAuth } from '@firebase/auth'
import { Button, Input, NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { functions, isValidEmail } from '../../../utils/Utils'
import { useTranslation } from 'react-i18next'

const EditEmail: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = getAuth()
  const email = auth.currentUser?.email || ''

  const [newEmail, setNewEmail] = useState(email)

  const handleEditEmail = () => {
    const sendConfirmChangeEmail = httpsCallable(functions, 'SendConfirmChangeEmail')
    const url = `${window?.location.origin}/change-email/callback?email=${email}&newEmail=${newEmail}`

    sendConfirmChangeEmail({ continueURL: url, email, newEmail })
      .then(() => {
        Toast.show({
          content: t('user.edit-email.message.send-email.success'),
          position: 'top',
          duration: 3000,
        })
      })
      .catch((error) => {
        Toast.show({ content: error.message, position: 'top', duration: 3000 })
      })
  }

  return (
    <div className="container">
      <NavBar
        className="fixed-top"
        style={{ '--border-bottom': '1px solid #EEEEEE', height: '55px' }}
        right={
          <Button fill="none" onClick={() => navigate('/')}>
            <CloseOutline color="#111827" />
          </Button>
        }
        onBack={() => navigate('/user')}
      >
        {t('user.edit-email.title')}
      </NavBar>
      <Space style={{ display: 'flex', flexDirection: 'column', padding: '75px 25px 0' }}>
        <Space style={{ fontSize: '15px', fontWeight: '400', color: '#666666' }}>{t('user.edit-email.title')}</Space>
        <Input
          value={newEmail || ''}
          onChange={(value) => setNewEmail(value)}
          placeholder="email"
          type="email"
          style={{ margin: '4px 0 64px', borderBottom: '1px solid #eeeeee', width: '100%' }}
        />
        <Button
          color="primary"
          fill="solid"
          loading="auto"
          style={{ width: '100%' }}
          disabled={newEmail === auth.currentUser?.email || !isValidEmail(newEmail)}
          onClick={handleEditEmail}
        >
          {t('user.edit-email.button.save')}
        </Button>
      </Space>
    </div>
  )
}

export default EditEmail
