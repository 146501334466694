import React from 'react'
import { Skeleton } from 'antd-mobile'

const PostsLoading: React.FC<{ isExpand: boolean }> = ({ isExpand }) => {
  return (
    <div
      className="container"
      style={{ backgroundColor: 'var(--main-background-color)', marginTop: isExpand ? '114px' : '55px' }}
    >
      {[1, 2, 3].map((number) => (
        <div key={number} style={{ padding: '24px 12px 0' }}>
          <Skeleton animated style={{ '--width': '100%', '--height': '269px', '--border-radius': '23px' }} />
        </div>
      ))}
    </div>
  )
}

export default PostsLoading
